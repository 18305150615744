import React, { useEffect, useState } from "react"
import { UtilisateurCard } from "../../../Components/Cards/Admin/UtilisateurCard"

export const Utilisateur = () => {
    const [isRefresh, setIsRefresh] = useState(false)
    const utilisateur = [
        {
            id: 1,
            nom: "Kristina Lauren",
            email: "admin@example.com",
            type: "admin"
        },
        {
            id: 1,
            nom: "Kristina Lauren",
            email: "admin@example.com",
            type: "admin"
        },
        {
            id: 1,
            nom: "Kristina Lauren",
            email: "admin@example.com",
            type: "admin"
        },
        {
            id: 1,
            nom: "Kristina Lauren",
            email: "admin@example.com",
            type: "admin"
        },
        {
            id: 1,
            nom: "Kristina Lauren",
            email: "admin@example.com",
            type: "admin"
        }
    ]

    useEffect(() => { }, [isRefresh])

    const trigerRefresh = () => { setIsRefresh(!isRefresh) }

    return (
        <div className="mt-20">
            <UtilisateurCard isButton />
            <div className="ml-60">
                <UtilisateurCard items={utilisateur} onRefresh={trigerRefresh} />
            </div>
        </div>
    )
} 