import React, { useEffect, useState } from "react";
import { ExamenNavigation } from "../../Components/NavBar/ExamenNavigation";
import { ExamenCardEtudiant } from "../../Components/Cards/Examen/ExamenCardEtudiant";
import { ExamenCard } from "../../Components/Cards/Examen/ExamenCard";
import { useAppStore } from "../../store/User/user";
import { PasserExamenCard } from "../../Components/Cards/Etudiant/PasserExamenCard";
import axios from "axios";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { AucunElement } from "../../Components/Cards/AucunElementCard";

export const Examen = () => {
    const [matiere, setMatiere] = useState([])
    const type = useAppStore.use.type()
    const [isLoading, setIsLoading] = useState(true);

    const fetchMatiere = async () => {
        try {
            const response = await axios.get(`http://91.134.91.43:8000/api/matieres`)
            setMatiere((prev) => {
                return response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMatiere()
    }, [])

    const [item, setItems] = useState(0)

    const renderItem = (items) => {
        switch (items) {
            case 0:
                return (
                    <ExamenCardEtudiant />
                )
            case 1:
                return (
                    <PasserExamenCard items={matiere} />
                )
            default:
                return null
        }
    }

    const changeItem = () => {
        setItems(1)
    }
    return (
        <div className="mt-20 w-full flex flex-col items-center h-full">
            {type === "enseignant" ? (
                <div className="w-full flex flex-col items-center">
                    <ExamenCard isButton />
                    <div className="w-1/3 ml-[15vw]">
                        <AucunElement item={"examen"} />
                    </div>
                </div>
            ) : (
                <div className="flex w-5/6 justify-center">
                    <ExamenNavigation ItemChanged={changeItem} />
                    <div className="w-1/2 h-full">{renderItem(item)} </div>
                </div>
            )}
        </div>
    );
};
