import React from "react";
import HomeCard from "../Components/Cards/Admin/HomeCard";

export const Home = () => {
    return (
        <div className="w-full h-full ">
            <HomeCard />
        </div>
    )
}
