import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OSInput } from "../../Input/OSInput";
import { OSButton } from "../../Button/OSButton";
import api from "../../../store/Api/Intercepteur";

export const UtilisateurCard = ({ isButton, items, onRefresh }) => {
    const navigate = useNavigate();
    const modalRef = useRef(null);

    const handleEditClick = (categorie, id) => {
    };

    const handleDeleteClicked = async (id) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer ce categorie");
        let response = false;
        if (confirmation) {
            try {
                await api.delete(`/categories/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
                onRefresh()
            } catch (error) {
                console.log(error);
            }
        }
    };


    return (
        <div className="w-full px-5">
            <div className="w-full">
                {!isButton ? (
                    <div className=" w-full">
                        <table className="table table-auto table-border w-full" data-datatable-table="true">
                            <thead>
                                <tr className="text-blue font-bold h-10 border-b">
                                    <th className="text-left pl-5">
                                        <span className="sort">
                                            <span className="sort-label">
                                                Nom
                                            </span>
                                            <span className="sort-icon">
                                            </span>
                                        </span>
                                    </th>
                                    <th className="text-left">
                                        <span className="sort">
                                            <span className="sort-label">
                                                E-mail
                                            </span>
                                            <span className="sort-icon">
                                            </span>
                                        </span>
                                    </th>
                                    <th className="text-left">
                                        <span className="sort">
                                            <span className="sort-label">
                                                Type
                                            </span>
                                            <span className="sort-icon">
                                            </span>
                                        </span>
                                    </th>
                                    <th className="w-32">
                                        <span className="sort">
                                            <span className="sort-label">
                                                Action
                                            </span>
                                            <span className="sort-icon">
                                            </span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index} className={`border-b h-10 ${index % 2 !== 0 ? '' : 'bg-gray-100'}`}>
                                        <td className="drop-shadow-2xl font-semibold text-black/80 pl-5">{item.nom}</td>
                                        <td className="drop-shadow-2xl font-semibold text-black/80">{item.email}</td>
                                        <td className="drop-shadow-2xl font-semibold text-black/80">{item.type}</td>
                                        <td className='text-center'>
                                            <button
                                                className="text-green-700/80 mr-5"
                                                onClick={() => handleEditClick(item.id)}
                                            >
                                                <FontAwesomeIcon icon={faEdit} color="green" />
                                            </button>
                                            <button
                                                className="text-green-700/80"
                                                onClick={() => handleDeleteClicked(item.id)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="red" width={16} height={16}>
                                                    <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                ) : (
                    <div className="flex justify-end mr-1 mb-5">
                        <button className="flex items-center justify-around hover:bg-green-100 bg-gray-200 px-6 rounded-lg py-2">
                            <FontAwesomeIcon icon={faPlusCircle} className="text-gray-700 mr-2" />
                            <p className="text-gray-700">Nouveau Utilisateur</p>
                        </button>
                    </div>
                )}
            </div>
        </div >
    );
};
