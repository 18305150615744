import React from 'react';
import { useParams } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppStore } from '../../store/User/user';

const backgroundImage = '/certificat.jpeg';

const GetCertificate = () => {
    const { id } = useParams();
    const user = useAppStore.use.user();
    const userInfo = user.info;

    const downloadCertificate = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [210, 130]
        });

        const img = new Image();
        img.src = backgroundImage;
        img.onload = function () {
            doc.addImage(img, 'JPEG', 0, 0, 210, 130);
            doc.setFontSize(22);
            doc.setTextColor(0, 0, 0);

            doc.setFontSize(16);
            doc.text(`${userInfo.name}`, 105, 60, null, null, 'center');
            doc.text('Mention: Très bien', 105, 70, null, null, 'center');
            doc.text('Mathématique', 105, 90, null, null, 'center');

            doc.save('certificat.pdf');
        };
    };

    const copyLink = () => {
        const link = `${window.location.origin}/certificat/${id}`;
        navigator.clipboard.writeText(link);
        toast.success('Lien copié dans le presse-papiers');
    };

    return (
        <div className="relative flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="p-4 relative rounded shadow-md w-[50vw] h-[73.5vh] flex flex-col justify-center items-center"
                style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "contain" }}
            >
                <p>{userInfo.name}</p>
                <p>Mention : Très bien</p>
                <p className='mt-40 absolute'>Chimie</p>
            </div>
            <div className="flex justify-between space-x-4 absolute bottom-6">
                <button onClick={downloadCertificate} className="bg-primary text-white py-2 px-4 rounded">
                    Télécharger le certificat
                </button>
                <button onClick={copyLink} className="bg-primary text-white py-2 px-4 rounded">
                    Copier le lien
                </button>
            </div>
            <ToastContainer />
        </div>
    );
};

export default GetCertificate;
