import React from "react";
import { SearchBar } from "../Components/Searchbar/SearchBar";

export const Recherche = () => {
    return (
        <div className="relative flex flex-col min-h-screen items-center justify-center">
            <div
                className="absolute inset-0 bg-cover bg-center filter w-full h-full"
                style={{
                    backgroundImage: `url('https://media.istockphoto.com/id/1663317152/fr/photo/jeune-femme-caucasienne-utilisant-un-ordinateur-portable-tout-en-travaillant-%C3%A0-domicile.webp?b=1&s=170667a&w=0&k=20&c=N58-jNPm3pq-ypXW3RvUgRww-jPsgRCTUo_McM5_dDo=')`,
                    backgroundSize: "cover",
                }}
            >
                <div className="w-full h-full bg-black/70"></div>
            </div>
            <div className="relative top-[-40px] flex w-1/2 max-sm:w-full flex-col items-center justify-between text-gray-200">
                <div className="flex flex-col items-center mb-5">
                    <p className="m-2 text-3xl font-bold">Cours</p>
                    <p className="mb-2 py-1.5 px-7 rounded-md bg-gray-400/60 font-bold">23 cours</p>
                </div>
                <div className="w-4/5 max-sm:w-5/6">
                    <SearchBar placeholder="recherche des cours" cour={true} />
                </div>
            </div>
        </div>
    )
}