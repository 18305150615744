import React, { useState, useEffect, useRef } from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"
import translate from "../../store/Translation/Langue"
import { UseLanguage } from "../../store/User/language"
import { translations } from "../../store/Translation/Langue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faLayerGroup, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useAppStore } from "../../store/User/user"

const pathCorrect = "border-b-4 border-[#06a440] font-normal text-[#06a440] px-2 h-14 flex flex-col justify-center"
const pathIncorrect = "text-gray-700 font-normal"

export const NavbarVisitor = () => {
    const { pathname } = useLocation()
    const language = UseLanguage.use.language()
    const logout = useAppStore.use.logout()
    const changeLanguage = UseLanguage.use.changeLanguage()
    const [selectionLanguage, setSelectionLanguage] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const dropdownRef = useRef(null)
    const menuRef = useRef(null)

    const handleLanguageChange = (lang) => {
        changeLanguage(lang)
        setSelectionLanguage(false)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSelectionLanguage(false)
        }
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [selectionLanguage, showMenu])


    return (
        <nav className="h-14 fixed flex justify-center border-gray-300 shadow-md bg-white w-full z-20">
            <div className="relative flex h-14 items-center justify-around w-5/6 max-md:justify-between max-md:pl-12 max-sm:pl-1 max-xl:w-full">
                {/*MENU SIDEBAR*/}
                <div className="lg:hidden max-lg:ml-3 flex flex-col justify-center items-around">
                    <button onClick={() => setShowMenu(!showMenu)} className="border border-gray-300 py-1 px-3">
                        <FontAwesomeIcon icon={faBars} className="text-gray-400" />
                    </button>
                </div>
                {/*NAVBAR*/}
                <div className="ml-[-30px]">
                    <img src="/logo.jpeg" alt="LOGO" className="w-9 h-9" />
                </div>
                <ul className="flex justify-between items-center w-2/3">
                    <div className="flex items-center justify-center gap-x-10">
                        <li className={`max-lg:hidden ${classNames(pathname === "/" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/" className="text-center">
                                <p>{translate(language, "home")}</p>
                            </Link>
                        </li>
                        <li className={`max-lg:hidden ${classNames(pathname === "/cours" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/cours">
                                <p className="text-center">{translate(language, "courses")}</p>
                            </Link>
                        </li>
                        <li className={`max-lg:hidden ${classNames(pathname === "/contact" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/contact">
                                <p className="text-center">{translate(language, "contact")}</p>
                            </Link>
                        </li>
                        <li className={`max-lg:hidden ${classNames(pathname === "/certificat" ? pathCorrect : pathIncorrect)}`}>
                            <Link to="/certificat">
                                <p className="text-center">{translate(language, "certificate")}</p>
                            </Link>
                        </li>
                    </div>
                    <div className="max-sm:right-1 flex justify-around items-center">
                        <li className={`col-span-1 mr-7 max-lg:hidden  ${classNames(pathname === "/recherche" ? "text-primary " : "text-gray-700")}`}>
                            <Link to="/recherche" className="w-full text-center">
                                <FontAwesomeIcon icon={faSearch} className="text-center" />
                            </Link>
                        </li>
                        <div ref={dropdownRef} className="max-md:hidden">
                            <button
                                onClick={() => setSelectionLanguage(!selectionLanguage)}
                                className="text-black/90 rounded-full mt-1 mr-7"
                            >
                                <img src={translate(language, "flag")} alt="language" className="w-4 h-3 object-cover" />
                            </button>
                            {selectionLanguage && (
                                <div className="absolute w-40 bg-white shadow rounded-md border-gray-100 top-9">
                                    {Object.keys(translations).map((langCode) => (
                                        <div key={langCode}>
                                            <button
                                                onClick={() => handleLanguageChange(langCode)}
                                                className="text-gray-600 py-1 flex ml-4 items-center"
                                            >
                                                <img src={translate(langCode, "flag")} alt="language" className="w-4 h-3 mr-2 object-cover" />
                                                <span>{translate(language, langCode)}</span>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <li className="col-span-1 bg-secondary shadow-primaryshadow rounded-md hover:bg-primary py-1 text-center text-white w-36 max-lg:bg-white max-lg:hover:bg-white max-lg:text-secondary max-lg:font-bold max-lg:hover:text-primary">
                            <Link to="/authentification">
                                <p>{translate(language, "login")}</p>
                            </Link>
                        </li>
                    </div>
                </ul>
            </div>
            <div
                ref={menuRef}
                className={`absolute flex items-start w-1/2 pl-3 pt-3 justify-between top-14 bg-white left-0 transition-all duration-200 linear h-screen`}
                style={{ left: showMenu ? '0px' : "-50vw" }}
            >
                <ul className="px-5 relative h-full">
                    <li className="py-2">
                        <Link to="/">
                            <p>Accueil</p>
                        </Link>
                    </li>
                    <li className="py-2">
                        <Link to="/cours">
                            <p>Cours</p>
                        </Link>
                    </li>
                </ul>
                <li className="absolute bottom-14 py-2 w-full flex flex-col bg-gray-200">
                    <Link to="/authentification" className="text-center w-full" onClick={() => logout()}>Deconnexion</Link>
                </li>
                <div>
                    <button onClick={() => setShowMenu(false)} className="px-4 py-3">
                        <FontAwesomeIcon icon={faXmark} className="text-black/80 w-5 h-5" />
                    </button>
                </div>
            </div>
        </nav>
    )
}
