import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { DashBoard } from "../Components/NavBar/Dashboard";
import { Navbar } from "../Components/NavBar/index";
import { NavbarVisitor } from "../Components/NavBar/NavbarVisitor";
import { useAppStore } from "../store/User/user";
import { Footer } from "../Components/Footer/Footer";

export const Layout = () => {
  const type = useAppStore.use.type()

  return (
    <div className="flex flex-col w-full overflow-x-hidden">
      <div className={`relative flex-1 flex-col w-full`}>
        <div className="z-40">
          {type === "administrateur" || type === "enseignant" ? (
            <DashBoard />
          ) : type === "etudiant" ? (
            <Navbar />
          ) : (
            <NavbarVisitor />
          )}
        </div>
        <div className="w-full mt-14">
          <Outlet />
        </div>
        <div className="w-full flex fle-col items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};
