import React, { useEffect, useRef, useState } from "react";
import { Auth } from "../../../Pages/Authentification/Auth";
import translate from "../../../store/Translation/Langue";
import { UseLanguage } from "../../../store/User/language";
import { useAppStore } from "../../../store/User/user";
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import api from "../../../store/Api/Intercepteur";
import { useNavigate } from "react-router-dom";


export const CoursCardVisiteur = ({ image, cout, id_cours, trigersuivi, sendResponseSuccess, sendResponseError }) => {
    const [showModal, setShowModal] = useState(false)
    const language = UseLanguage.use.language()
    const type = useAppStore.use.type()
    const modalRef = useRef()
    const navigation = useNavigate()

    const handleNotificationOpen = () => {
        setShowModal(true);
    };

    const handleNotificationClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleNotificationClose()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const BuyCourseHandle = async () => {
        try {
            const response = await api.post(
                `/userCours`,
                { id_cours: id_cours },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            trigersuivi()
            handleNotificationClose()
            return sendResponseSuccess("Vous avez achetez ce cours")
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return sendResponseError("Votre crédit est insuffisant!");
            } else {
                console.log(error);
            }
        }
    };

    return (
        <div className="relative bg-white w-80 max-lg:w-full mb-5 rounded-2xl max-lg:mt-10">
            <div className="rounded-2xl w-80 pb-10 max-lg:w-full">
                <div className="w-80 max-lg:w-full">
                    <img src={image} alt="images" className="object-cover w-80 h-58 rounded-t-2xl max-lg:w-full max-lg:h-[14.5rem]" />
                </div>
                <div className="p-3">
                    <p className="text-[#43d477] font-extrabold text-3xl my-3 w-full text-center">${cout}</p>
                    <button onClick={() => setShowModal(true)} className="w-full bg-gradient-to-r from-secondary from-10% via-primary via-50% to-secondary to-90%  p-2 text-white bottom-16 rounded-md" >{translate(language, "buy")}</button>
                </div>
            </div>
            {
                showModal && (
                    <Modal
                        isOpen={showModal}
                        onRequestClose={handleNotificationClose}
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                    >
                        {type === "" ? (
                            <Auth handleCloseModal={handleNotificationClose} />
                        ) : (
                            <div ref={modalRef} className="bg-white rounded-lg shadow-lg w-1/3 transform transition-all duration-300 ease-in-out">
                                <button onClick={handleNotificationClose} className="pt-2 pr-2 w-full flex justify-end">
                                    <FontAwesomeIcon icon={faXmark} className="text-gray-600 w-5 h-5" />
                                </button>
                                <div className="flex flex-col items-center gap-4 px-6 pb-6">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="h-12 w-12 text-red-500"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <h4 className="text-black/60 text-2xl font-semibold text-center">Débloquez ce cours pour <span className="text-primary">${cout}</span></h4>
                                    <p className="text-center font-normal">
                                        Vous êtes sur le point de débloquer ce cours en utilisant votre crédit. Voulez-vous continuer ?
                                    </p>
                                    <div className="flex gap-2 mt-4 w-full justify-between">
                                        <button className="text-white py-2 px-4 rounded-md bg-gradient-to-r from-red-400 to-red-500" onClick={handleNotificationClose}>Annuler</button>
                                        <button className="bg-gradient-to-r from-secondary to-primary text-white py-2 px-4 rounded" onClick={BuyCourseHandle}>Oui, débloquer</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
        </div>
    )
}
