import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const pathCorrect = "border-b-4 border-secondary text-secondary mx-10 px-2 h-10 flex flex-col justify-center"
const pathIncorrect = "text-gray-700 mx-10"

export const ExamenNavigation = ({ ItemChanged }) => {
    const { pathname } = useLocation()

    return (
        <nav className="relative z-20 mt-40 flex flex-col items-center justify-center w-72">
            <ul className="fixed flex flex-col items-center justify-around">
                <li className="shadow-md rounded-md my-7 border w-40 border-gray-100 hover:bg-[#e4b3bd80] h-20 px-4 flex flex-col items-center justify-center text-gray-700">
                    <Link to="/examen" className="text-center w-full">Examen a passer</Link>
                </li>
                <li className="shadow-md rounded-md my-7 border w-40 border-gray-100 hover:bg-[#e4b3bd80] h-20 px-4 flex flex-col items-center justify-center text-gray-700">
                    <button onClick={ItemChanged} className="text-center w-full">Passer un examen</button>
                </li>
                <li className="shadow-md rounded-md my-7 border w-40 border-gray-100 hover:bg-[#e4b3bd80] h-20 px-4 flex flex-col items-center justify-center text-gray-700">
                    <Link to="/examen/resultat">Resultat</Link>
                </li>
            </ul>
        </nav>
    )
}