import React, { useState } from "react";

export const ExamenCardEtudiant = ({ item }) => {
    const today = new Date().toLocaleDateString()
    const [items, setItems] = useState([{ exam: "Java", date: today }, { exam: "Mobile", date: today }, { exam: "Node", date: today }])

    return (
        <div className="flex flex-col w-full items-center ">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <table className="min-w-full text-left text-sm font-light">
                            <thead className="border-b font-medium dark:border-neutral-500">
                                <tr>
                                    <th scope="col" className="px-6 py-4">#</th>
                                    <th scope="col" className="px-6 py-4">Matiere</th>
                                    <th scope="col" className="px-6 py-4">Date d'examen</th>
                                </tr>
                            </thead>
                            <tbody>

                                {items.map((item, index) => (
                                    <tr key={index}
                                        className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium">{index}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{item.exam}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{item.date}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}