import React from 'react';

const DeleteCard = ({ onConfirm, onCancel }) => {
    return (
        <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed bottom-0 z-50 mx-4 md:relative">
            <div className="md:flex items-center">
                <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                    <i className="bx bx-error text-3xl text-red-500">
                        &#9888;
                    </i>
                </div>
                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                    <p className="font-bold text-red-500">Warning!</p>
                    <p className="text-sm text-gray-700 mt-1">You will lose all of your data by deleting this. This action cannot be undone.
                    </p>
                </div>
            </div>
            <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                <button id="confirm-delete-btn" onClick={onConfirm} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                    Delete
                </button>
                <button id="confirm-cancel-btn" onClick={onCancel} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default DeleteCard;
