import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/User/user";
import api from "../../store/Api/Intercepteur";
import { AucunElement } from "../../Components/Cards/AucunElementCard";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-modal";
import DeleteCard from "../../Components/DeleteConfirmation/DeleteCard";

function ViewChapitre({ selectedItem, sendResponseSuccess, sendResponseError }) {
    const type = useAppStore.use.type();
    const location = useLocation();
    const items = location.state.items || [];
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(null)
    const [currentChapter, setCurrentChapter] = useState([])
    const [idchapitre, setIdChapitre] = useState(location.state.idchapitre)
    const [showSuppressionModal, setShowSuppressionModal] = useState(false)
    const modalSuppressionRef = useRef(null);

    useEffect(() => {
        const index = items.findIndex(i => i.id === idchapitre)
        setCurrentChapter(items[index])
        setCurrentIndex(index)
    }, [currentIndex])

    const ModifierChapitreClicked = () => {
        navigate('/Chapitre/modification-chapitre', { state: { items: items, chapitre: currentChapter } });
    };

    const SupprimerClicked = async () => {
        setShowSuppressionModal(true);
    };

    const confirmDelete = async () => {
        try {
            await api.delete(`chapitres/${currentChapter.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setShowSuppressionModal(false)
            toast.success("Chapitre supprimé avec succès!", {
                onClose: () => {
                    if (currentIndex < items.length - 1) {
                        setCurrentChapter(items[currentIndex + 1])
                        setCurrentIndex(currentIndex + 1);
                    } else if (currentIndex > 0) {
                        setCurrentChapter(items[currentIndex - 1])
                        setCurrentIndex(currentIndex - 1);
                    } else {
                        setIdChapitre(-1)
                    }
                }
            })
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setShowSuppressionModal(false)
                toast.error("Une erreur s'est produite lors de la suppresion du chapitre!")
            }
            console.log(error)
        }
    }

    const cancelDelete = async () => {
        setShowSuppressionModal(false)
    }

    const next = () => {
        if (currentIndex < items.length - 1) {
            setCurrentChapter(items[currentIndex + 1])
            setCurrentIndex(currentIndex + 1);
        }
    };

    const previous = () => {
        if (currentIndex > 0) {
            setCurrentChapter(items[currentIndex - 1])
            setCurrentIndex(currentIndex - 1);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalSuppressionRef.current && !modalSuppressionRef.current.contains(event.target)) {
                setShowSuppressionModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (idchapitre < 0) {
        console.error("Index out of bounds");
        return <div className="min-h-screen w-full flex justify-center items-center">
            <div className="w-1/3">
                <AucunElement item="contenu" />
            </div>
        </div>;
    }

    return (
        <div className="w-full px-52 pt-2 min-h-screen">
            {type === "enseignant" ? (
                <div className="ml-20 mt-3">
                    <div className="flex justify-between">
                        <button
                            onClick={previous}
                            disabled={currentIndex === 0}
                            className={`flex items-center rounded-lg px-2 font-semibold text-secondary hover:text-white hover:bg-primary border border-secondary ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            <FontAwesomeIcon icon={faArrowLeftLong} />
                        </button>
                        <p className="text-center text-3xl font-bold text-gray-800">{currentChapter.titre}</p>
                        <div className="flex">
                            <button onClick={ModifierChapitreClicked} className="mr-3 px-4 py-1.5 rounded font-bold bg-primary text-white hover:bg-primary/80">Modifier</button>
                            <button onClick={SupprimerClicked} className="text-secondaryblue px-4 py-1.5 rounded font-bold bg-gray-300 mr-3 hover:bg-gray-300/80">Supprimer</button>
                            <button
                                onClick={next}
                                disabled={currentIndex === items.length - 1}
                                className={`flex items-center rounded-lg border text-secondary hover:text-white border-secondary hover:bg-secondary px-3 font-semibold ${currentIndex === items.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </button>
                        </div>
                    </div>
                    <div className="p-5 border mt-3 border-gray-200 rounded-lg h-screen overflow-y-auto scrollbar-thin scrollbar-w-2 scrollbar-track-rounded-md scrollbar-thumb-light scrollbar-thumb-rounded-full">
                        <div dangerouslySetInnerHTML={{ __html: currentChapter.contenues }} />
                    </div>
                    <ToastContainer />
                </div>
            ) : (
                <div>
                    <div className="flex justify-between">
                        <button
                            onClick={previous}
                            disabled={currentIndex === 0}
                            className={`flex items-center rounded-lg px-2 font-semibold text-secondary hover:text-white hover:bg-primary border border-secondary ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            <FontAwesomeIcon icon={faArrowLeftLong} />
                        </button>
                        <p className="text-center text-3xl font-bold text-gray-800">{currentChapter.titre}</p>
                        <div className="flex">
                            <button
                                onClick={next}
                                disabled={currentIndex === items.length - 1}
                                className={`flex items-center rounded-lg border text-secondary hover:text-white border-secondary hover:bg-secondary px-3 font-semibold ${currentIndex === items.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </button>
                        </div>
                    </div>
                    <div className="p-5 border mt-3 border-gray-200 rounded-lg h-screen overflow-y-auto scrollbar-thin scrollbar-w-2 scrollbar-track-rounded-md scrollbar-thumb-light scrollbar-thumb-rounded-full">
                        <div dangerouslySetInnerHTML={{ __html: currentChapter.contenues }} />
                    </div>
                </div>
            )}
            <Modal
                isOpen={showSuppressionModal}
                onRequestClose={() => setShowSuppressionModal(false)}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayclassName="fixed inset-0 bg-black bg-opacity-50"
                shouldCloseOnOverlayClick={true}
            >
                <div ref={modalSuppressionRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[20vw]">
                    <DeleteCard onCancel={cancelDelete} onConfirm={confirmDelete} />
                </div>
            </Modal>
        </div>
    );
}

export default ViewChapitre;
