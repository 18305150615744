import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faCalendar, faChevronDown, faChevronUp, faLayerGroup, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/User/user";
import { UseLanguage } from "../../store/User/language";
import translate, { translations } from "../../store/Translation/Langue";

export const DashBoard = () => {
    const [select, setSelect] = useState(false)
    const type = useAppStore.use.type()
    // const type = "administrateur"
    const navigation = useNavigate()
    const logout = useAppStore.use.logout()
    const { pathname } = useLocation()
    const language = UseLanguage.use.language()
    const changeLanguage = UseLanguage.use.changeLanguage()
    const [selectionLanguage, setSelectionLanguage] = useState(false);
    const dropdownRef = useRef(null);
    const modalRef = useRef()
    const user = useAppStore.use.user()
    const userInfo = user.info

    const handleLanguageChange = (lang) => {
        changeLanguage(lang)
        setSelectionLanguage(false)
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSelectionLanguage(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [selectionLanguage])

    const handleNavItemClick = (href) => {
        navigation(`${href}`)
    };

    const SelectOnClick = () => {
        setSelect(!select)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setSelect(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    })

    return (
        <div>
            <aside className="w-60 bg-white border-r z-30 fixed border-gray-300 min-h-full h-screen flex flex-col items-center pt-5 pb-2 space-y-7">
                <div>
                    <img src="/logo.jpeg" alt="LOGO" className="w-9 h-9" />
                </div>
                <div className="w-full pr-3 flex flex-col gap-y-2 text-gray-500 fill-gray-500 text-sm">
                    <div className="flex flex-col items-center">
                        <div className="rounded-full w-32 h-32">
                            <img src="https://media.istockphoto.com/id/938709362/fr/photo/portrait-dune-petite-fille.webp?b=1&s=170667a&w=0&k=20&c=jYAaLMfhRqyzxWBl3sz_fPB03KIpv9WmsQGGjmSJkQE=" alt="profile" className="rounded-full w-full h-full object-cover" />
                        </div>
                        <Link to="/Profile" className="py-1 font-extrabold text-lg text-blue">{userInfo.name}</Link>
                        <div className="w-40 flex justify-around py-1">
                            <button className="border-r pr-5">
                                <p className="text-blue font-bold text-[16px]">0</p>
                                <p>
                                    <strong>{translate(language, "courses")}</strong>
                                </p>
                            </button>
                            <button className="pl-2">
                                <p className="text-blue font-extrabold text-[16px]">0</p>
                                <strong>Follower</strong>
                            </button>
                        </div>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"></path>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/" ? 'text-thirdly' : ''}`}>{translate(language, "home")}</span>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "cours" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/cours")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/cours" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/cours" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M9 3C6.23858 3 4 5.23858 4 8C4 10.7614 6.23858 13 9 13C11.7614 13 14 10.7614 14 8C14 5.23858 11.7614 3 9 3ZM3 21V20C3 17.7909 4.79086 16 7 16H10C12.2091 16 14 17.7909 14 20V21H3ZM16.42 14.23C14.88 13.48 12.99 13 12 13C8.69 13 6 15.69 6 19H16C16 17.98 16.21 16.96 16.42 14.23ZM17 8C17 9.6572 17.2102 11.2899 17.52 12.47C17.72 13.2 18.34 14 19.21 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H15V21H20C20.5523 21 21 20.5523 21 20V16C21 15.4477 20.5523 15 20 15H19.21C18.53 15 17.86 14.63 17.52 14.03C17.3102 13.71 17.17 13.35 17.11 13C17.0705 12.6941 17.0083 12.3698 16.92 12.03C16.83 11.69 16.71 11.33 16.58 10.96C16.3 10.18 15.71 9.55 15 9.16V9C15.5523 9 16 8.55228 16 8Z"></path>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/cours" ? 'text-thirdly' : ''}`}>{translate(language, "courses")}</span>
                    </div>
                    {type === "administrateur" && (
                        <div>
                            <div
                                className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/categorie" ? 'bg-gray-200' : ''}`}
                                onClick={() => handleNavItemClick("/categorie")}
                            >
                                <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                    <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/categorie" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                                </div>
                                <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/categorie" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <g>
                                        <path d="M19 2H9c-1.11 0-2 .89-2 2v5.586l-4.707 4.7v0c-.4.39-.4 1.02 0 1.41 .18.18.44.29.7.29v5 0c0 .55.44 1 1 1h16v0c.55 0 1-.45 1-1v-17c0-1.11-.9-2-2-2Zm-8 18H5v-5.586l3-3 3 3V20Zm8 0h-6v-4 0c.55 0 .99-.45 1-1 0-.27-.11-.53-.3-.72L8.99 9.57V3.984h10v16Z"></path>
                                        <path d="M11 6h2v2h-2Zm4 0h2v2h-2Zm0 4.03h2v1.96h-2Zm0 3.96h2v2h-2Zm-8 1h2v2H7Z"></path>
                                    </g>
                                </svg>
                                <span className={`font-QuicksandMedium ${pathname === "/categorie" ? 'text-thirdly' : ''}`}>Categorie</span>
                            </div>
                            <div
                                className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/utilisateur" ? 'bg-gray-200' : ''}`}
                                onClick={() => handleNavItemClick("/utilisateur")}
                            >
                                <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                    <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/utilisateur" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                                </div>
                                <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/utilisateur" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
                                </svg>
                                <span className={`font-QuicksandMedium ${pathname === "/utilisateur" ? 'text-thirdly' : ''}`}>utilisateur</span>
                            </div>
                        </div>
                    )}
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/exercice" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/exercice")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/exercice" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/exercice" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M3 17v2h18v-2H3zM9 3C7.9 3 7 3.9 7 5c0 2.5 3 4 5 4s5-1.5 5-4c0-1.1-.9-2-2-2s-2 .9-2 2c0 .55-.45 1-1 1s-1-.45-1-1c0-1.1-.9-2-2-2zm0 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 9c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm9-9c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/exercice" ? 'text-thirdly' : ''}`}>{translate(language, "exercise")}</span>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/paiment" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/paiment")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/paiment" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/paiment" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g>
                                <path d="M19 2H9c-1.11 0-2 .89-2 2v5.586l-4.707 4.7v0c-.4.39-.4 1.02 0 1.41 .18.18.44.29.7.29v5 0c0 .55.44 1 1 1h16v0c.55 0 1-.45 1-1v-17c0-1.11-.9-2-2-2Zm-8 18H5v-5.586l3-3 3 3V20Zm8 0h-6v-4 0c.55 0 .99-.45 1-1 0-.27-.11-.53-.3-.72L8.99 9.57V3.984h10v16Z"></path>
                                <path d="M11 6h2v2h-2Zm4 0h2v2h-2Zm0 4.03h2v1.96h-2Zm0 3.96h2v2h-2Zm-8 1h2v2H7Z"></path>
                            </g>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/paiment" ? 'text-thirdly' : ''}`}>Paiment</span>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-4 ${pathname === "/examen/creer-examen" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/examen")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/examen" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <FontAwesomeIcon icon={faCalendar} className={`transition-colors duration-200 group-hover:text-thirdly ${pathname === "/examen/creer-examen" ? 'text-thirdly' : 'text-gray-500'}`} />
                        <span className={`font-QuicksandMedium ${pathname === "/examen" ? 'text-thirdly' : ''}`}>{translate(language, "exam")}</span>
                    </div>
                </div>

                {/* <div className="w-full pr-3 flex flex-col gap-y-1 text-gray-500 fill-gray-500 text-sm">

                    <div className="font-QuicksandMedium pl-4 text-gray-400/60 text-xs text-[11px] uppercase">Profile</div>

                    <div className="w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded-lg">
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className="absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-red-600 transition-all duration-300"></div>
                        </div>
                        <div className="group-hover:bg-white/10 w-full group-active:scale-95 self-stretch pl-2 rounded flex items-center space-x-2 transition-all duration-200 dark:group-hover:text-white dark:hover:text-white text-sm" href="#">
                            <svg className="h-5 w-5 group-hover:fill-red-600 dark:fill-gray-600  transition-colors duration-200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8199 22H10.1799C9.71003 22 9.30347 21.673 9.20292 21.214L8.79592 19.33C8.25297 19.0921 7.73814 18.7946 7.26092 18.443L5.42392 19.028C4.97592 19.1709 4.48891 18.9823 4.25392 18.575L2.42992 15.424C2.19751 15.0165 2.27758 14.5025 2.62292 14.185L4.04792 12.885C3.98312 12.2961 3.98312 11.7019 4.04792 11.113L2.62292 9.816C2.27707 9.49837 2.19697 8.98372 2.42992 8.576L4.24992 5.423C4.48491 5.0157 4.97192 4.82714 5.41992 4.97L7.25692 5.555C7.50098 5.37416 7.75505 5.20722 8.01792 5.055C8.27026 4.91269 8.52995 4.78385 8.79592 4.669L9.20392 2.787C9.30399 2.32797 9.71011 2.00049 10.1799 2H13.8199C14.2897 2.00049 14.6958 2.32797 14.7959 2.787L15.2079 4.67C15.4887 4.79352 15.7622 4.93308 16.0269 5.088C16.2742 5.23078 16.5132 5.38736 16.7429 5.557L18.5809 4.972C19.0286 4.82967 19.515 5.01816 19.7499 5.425L21.5699 8.578C21.8023 8.98548 21.7223 9.49951 21.3769 9.817L19.9519 11.117C20.0167 11.7059 20.0167 12.3001 19.9519 12.889L21.3769 14.189C21.7223 14.5065 21.8023 15.0205 21.5699 15.428L19.7499 18.581C19.515 18.9878 19.0286 19.1763 18.5809 19.034L16.7429 18.449C16.5103 18.6203 16.2687 18.7789 16.0189 18.924C15.7567 19.0759 15.4863 19.2131 15.2089 19.335L14.7959 21.214C14.6954 21.6726 14.2894 21.9996 13.8199 22ZM11.9959 8C9.78678 8 7.99592 9.79086 7.99592 12C7.99592 14.2091 9.78678 16 11.9959 16C14.2051 16 15.9959 14.2091 15.9959 12C15.9959 9.79086 14.2051 8 11.9959 8Z"></path>
                            </svg>
                            <span className={`font-QuicksandMedium ${pathname === 5 ? 'text-thirdly' : ''}`}>Settings</span>
                        </div>
                    </div>
                </div> */}
            </aside>
            <header className={`fixed z-20 pl-64 pr-5 w-full bg-white h-16 border-b border-ray-300 shadow flex items-center justify-between  ${pathname === "/" && type === "administrateur" ? "justify-end" : ""} p-3`}>
                <div className={`relative flex ml-3 justify-start items-center w-1/2 ${pathname === "/" && type === "administrateur" ? "w-0" : ""}`}>
                    <FontAwesomeIcon icon={faSearch} color="gray" className={`mx-3 ${pathname === "/" && type === "administrateur" ? "w-0" : ""}`} />
                    <input type="text" id="recherche" name="recherche" placeholder={`${pathname === "/" && type === "administrateur" ? "" : "recherche..."}`} disabled={`${pathname === "/" && type === "administrateur" ? true : false}`}
                        className={`absolute h-11 pl-8 border border-gray-300 bg-transparent rounded focus:outline-none focus:ring-1 focus:border-primary w-1/2 ${pathname === "/" && type === "administrateur" ? "border-none" : ""}`}
                    />
                </div>
                <div className="w-1/2 pl-5 flex items-center justify-end">
                    <div ref={dropdownRef} className="relative mr-4">
                        <button
                            onClick={() => setSelectionLanguage(!selectionLanguage)}
                            className="text-black/90"
                        >
                            <img src={translate(language, "flag")} alt="language" className="w-4 h-3" />
                        </button>
                        {selectionLanguage && (
                            <div className="absolute w-40 bg-white shadow rounded-md border-gray-100 top-9">
                                {Object.keys(translations).map((langCode) => (
                                    <div>
                                        <button
                                            key={langCode} onClick={() => handleLanguageChange(langCode)}
                                            className="text-gray-600 py-1 pl-3"
                                        >
                                            {translate(language, langCode)}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <FontAwesomeIcon icon={faBell} color="gray" className="mr-7" />
                    <div>
                        <div className="flex justify-around">
                            <img src="/images/user.jpg" alt="user" className="w-7 h-7 rounded-full mr-2" />
                            <button onClick={SelectOnClick} className="flex items-center">
                                <p className="text-slate-950 mr-2 font-bold">{userInfo.name}</p>
                                <FontAwesomeIcon icon={select ? faChevronUp : faChevronDown} color="gray" size="xs" />
                            </button>
                        </div>
                        {select && (
                            <div ref={modalRef} className="absolute top-16 bg-white z-30 border border-gray-100 shadow w-36 rounded">
                                <div className="w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200">
                                    <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                        <div className="absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300"></div>
                                    </div>
                                    <div className="group-hover:bg-white/10 w-full group-active:scale-95 self-stretch pl-2 rounded flex items-center space-x-2 transition-all duration-200 text-gray-600 text-sm h-11" href="#">
                                        <svg className="h-5 w-5 group-hover:fill-thirdly dark:fill-gray-600  transition-colors duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                                            <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
                                        </svg>
                                        <Link to="/profile" className="font-QuicksandMedium">Profile</Link>
                                    </div>
                                </div>
                                <div className="w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200">
                                    <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                        <div className="absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300"></div>
                                    </div>
                                    <div className="group-hover:bg-white/10 w-full group-active:scale-95 self-stretch pl-2 rounded flex items-center space-x-2 transition-all duration-200 text-gray-600 text-sm h-11" href="#">
                                        <svg className="h-5 w-5 group-hover:fill-thirdly dark:fill-gray-600  transition-colors duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M19 21H5C3.89543 21 3 20.1046 3 19V15H5V19H19V5H5V9H3V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM11 16V13H3V11H11V8L16 12L11 16Z"></path>
                                        </svg>
                                        <Link to="/authentification" className="font-QuicksandMedium" onClick={() => logout()}>Deconnexion</Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </header>
        </div>
    )
}