import React from "react";
import { Link } from "react-router-dom";
import { LoginInput } from "../../Components/Input/LoginInput";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { OSButton } from "../../Components/Button/OSButton";

export const ResetPassword = () => {
    return (
        <div id="content" role="main" className="w-full h-[100vh] flex flex-col justify-center items-center max-w-md mx-auto p-6">
            <div className="mt-7 bg-white w-80 rounded-xl shadow-cardShadow border-2 border-indigo-300">
                <div className="p-4 sm:p-7">
                    <div className="text-center">
                        <h1 className="block text-2xl font-bold text-gray-800">Forgot password?</h1>
                        <p className="mt-2 text-sm text-gray-600">
                            Remember your password?
                            <Link to="/Authentification" className="text-primary ml-1 decoration-2 hover:underline font-medium" href="#">
                                Login here
                            </Link>
                        </p>
                    </div>
                    <div className="mt-5">
                        <form className="flex flex-col gap-3 items-center">
                            <LoginInput icon={faEnvelope} placeholder="Adresse Email" id="email" />
                            <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                            <OSButton value="Reset Password" />
                        </form>
                    </div>
                </div>
            </div>
            <p className="mt-3 flex justify-center items-center text-center divide-x divide-gray-300 dark:divide-gray-700">
                <a className="pr-3.5 inline-flex items-center gap-x-2 text-sm text-gray-600 decoration-2 hover:underline hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-200" href="#" target="_blank">
                    <svg className="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                    </svg>
                    View Github
                </a>
                <a className="pl-3 inline-flex items-center gap-x-2 text-sm text-gray-600 decoration-2 hover:underline hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-200" href="#">
                    Contact us!
                </a>
            </p>
        </div>
    )
}