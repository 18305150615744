import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OSInput } from "../../Components/Input/OSInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { LoginPswrdInput } from "../../Components/Input/LoginPswrdInput";
import { LoginInput } from "../../Components/Input/LoginInput";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { useAppStore } from "../../store/User/user";

export const Auth = ({ handleCloseModal, trigersuivi }) => {
    const [name, setname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setpassword] = useState("")
    const [confiramtionpassword, setConfirmationpassword] = useState("")
    const [type_utilisateur, settype_utilisateur] = useState("etudiant")
    const [inscription, setInscription] = useState(true)
    const register = useAppStore.use.register()
    const login = useAppStore.use.login()
    const navigate = useNavigate();
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setError("Le mot de passe doit contenir au moins 8 caractères.");
        } else {
            setError("");
            if (confiramtionpassword === password) {
                const result = await register(name, email, password, type_utilisateur)
                if (result.success) {
                    setInscription(false)
                }
            }
            else {
                setError("Le mot de passe doit etre la meme.")
            }
        }
    };

    const handleSubmitAuth = async (e) => {
        e.preventDefault();
        const result = await login(email, password);
        if (result.success) {
            handleCloseModal()
        } else {
            console.log(result.error);
        }
    }

    const modalRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleCloseModal()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div className="flex bg-white flex-col items-start pb-10 w-80 rounded-lg" ref={modalRef}>
            <button onClick={handleCloseModal} className="pt-2 pr-2 w-full flex justify-end">
                <FontAwesomeIcon icon={faXmark} className="text-gray-600 w-5 h-5" />
            </button>
            {
                inscription ? (
                    <div className="w-full px-5">
                        <p className="my-1 text-gray-600">Vous devez vous inscrire pour être éligible aux cours.</p>
                        <form onSubmit={handleSubmit} className="max-w-xs w-full">
                            <LoginInput icon={faUser} id="name" placeholder="name" state={((e) => setname(e.target.value))} />
                            <LoginInput icon={faEnvelope} id="name" placeholder="email" state={((e) => setEmail(e.target.value))} />
                            <LoginPswrdInput id="Mot_de_passe" error={error} placeholder="Mot de passe" state={((e) => setpassword(e.target.value))} />
                            <LoginPswrdInput id="confirmMdp" error={error} placeholder="Confirmer le mot de passe" state={((e) => setConfirmationpassword(e.target.value))} />
                            <button type="submit" className="w-full py-2 px-4 mt-2 text-sm font-semibold rounded text-white bg-gradient-to-r from-secondary to-primary hover:bg-thirdly focus:outline-none">
                                S'inscrire
                            </button>
                        </form>
                        <p className="text-gray-600 mt-4 text-sm">
                            Vous avez déjà un compte? <button onClick={() => setInscription(false)} className="text-blue-gray-800 font-bold">Se connecter</button>
                        </p>
                    </div>
                ) : (
                    <div className="w-full px-5">
                        <p className="my-7 text-gray-900 font-bold">Se connecter</p>
                        <form onSubmit={handleSubmitAuth} className="max-w-xs w-full">
                            <LoginInput icon={faUser} id="Email" placeholder="Email" state={((e) => setEmail(e.target.value))} />
                            <LoginPswrdInput id="Mot_de_passe" placeholder="Mot de passe" state={((e) => setpassword(e.target.value))} />
                            <button type="submit" className="w-full py-2 px-4 mt-2 text-sm font-semibold rounded text-white bg-gradient-to-r from-secondary to-primary hover:bg-thirdly focus:outline-none">
                                Connexion
                            </button>
                        </form>
                        <p className="text-gray-600 mt-4 text-sm text-center">
                            Pas de compte? <button onClick={() => setInscription(true)} className="text-blue-gray-800 font-bold">S'inscrire</button>
                        </p>
                    </div>
                )
            }
        </div>
    );
};
