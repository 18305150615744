export const translations = {
    fr: {
        //Language
        fr: "Français",
        en: "Anglais",
        //Loading
        loading: "Chargement",
        //Flag
        flag: "/Flag/french.png",
        //NavBar
        home: "Accueil",
        courses: "Cours",
        contact: "Contact",
        register: "S'inscrire",
        login: "Se connecter",
        exam: "Examen",
        profile: "Profil",
        certificat: "Certificat",
        addPoint: "Acheter credit",
        exercise: "Exercice",
        //USER
        students: "étudiant",
        teacher: "enseignant",
        employee: "employé",
        business_man: "homme d'affaire",
        colaborator: "collaborateur",
        //Home
        welcome: "Bienvenue cher ",
        titleAfterWelcome: "Bienvenue sur notre plateforme de formation et de remise à niveau dédiée aux employés et aux professionnels ambitieux. Que vous souhaitiez développer de nouvelles compétences, approfondir vos connaissances actuelles ou vous préparer à de nouvelles opportunités, nous avons les ressources et les cours qu'il vous faut.",
        search: "Rechercher cours",
        hi: "Bonjour",
        youHave: "Vous avez",
        newEvent: "nouveaux événements",
        viewAllEvent: "Voir tous les événements",
        dashboard: "Tableau de bord",
        chargeAccount: "Charger le compte",
        titreCarousel1: "Suivi Régulier du Cours",
        descriptioCarousel1: "Gardez facilement une trace de votre progression grâce au suivi régulier de nos cours.Restez au top de vos objectifs d'apprentissage avec des rappels et des mises à jour réguliers.",
        titreCarousel2: "Contenu de Cours Diversifié",
        descriptioCarousel2: "Nos cours offrent une riche combinaison de vidéos, de documents textuels et d'images pour améliorer votre expérience d'apprentissage. Engagez-vous avec du contenu sous différents formats pour une compréhension complète..",
        titreCarousel3: "Certification à la Clé",
        descriptioCarousel3: "Obtenez une certification reconnue à la fin de votre formation. Mettez en avant vos nouvelles compétences et connaissances auprès des employeurs potentiels.",
        //prof homecard 
        balance: "Solde du compte",
        purchased: "Cours acheté",
        meeting: "réunion",
        yourStudent: "Vos étudiants",
        //Cours
        yaCourse: "Vos cours",
        interestingCourse: "Cours interressants",
        in: "Dans",
        hour: "heures",
        followed: "Suivi",
        //Details cours
        createdBy: "Créer par",
        yllLearn: "Qu'allez vous Apprendre ? ",
        aboutCourse: "À propos de cet cours",
        addCard: "Ajouter aux cartes",
        buy: "Acheter maitennant !",
        payInstallment: "Payer en plusieurs fois",
        //Profile 
        following: "",
        follower: "",
        //Footer
        AboutUs: "À propos de nous",
        privacyPolicy: "Politique de confidentialité",
        condition: "Conditions d'utilisation",
        plan: "Plan du site",
        droitDauteur: "Tous droits réservés",
        explore: "Explorez, grandissez, apprenez plus",
        receiveThe: "Recevoir le",
        ourDomain: "Nos domaines",
        ourAdress: "Notre adresse",
        sendEmail: "Envoyer l'email",
        //Contact
        infoContact: "Information de contact",
        //Input: 
        name: "Nom",
        phone: "Téléphone",
        subject: "Sujet",
        sendMessage: "Envoyer le message",
        questionContact: "Avez-vous une grande idée ou une marque à développer et besoin d'aide ?",
        //Auth
        welcomeAuth: "Bienvenue",
        WantVisited: "Voulez-vous visiter notre plateforme ?",
        clickHere: "Cliquez ici",
        EmailAdress: "Adresse Email",
        password: "Mot de passe",
        ForgotPswd: "Mot de passe oublié ?",
        SingUp: "S'incrire",
        NoAccount: "Vous n'avez pas encore un compte ?",
        //Inscription
        createAccount: "Créer un compte",
        ConfirmaPswd: "Confirmer le mot de passe",
        student: "Etudiant",
        teacher: "Enseignant",
        HaveAccount: " Vous avez déjà un compte ?",
        SingIn: "Se connecter",
        //Certificat
        certificate: "Certificat",
    },
    en: {
        //Language
        fr: "French",
        en: "English",
        //Loading
        loading: "loading",
        //Flag
        flag: "/Flag/usa.jpeg",
        //Navbar
        home: "Home",
        courses: "Courses",
        contact: "Contact",
        register: "Register",
        login: "Login",
        certificat: "Certificate",
        addPoint: "Buy credit",
        exercise: "Exercise",

        exam: "Exam",
        profile: "Profile",
        //USER
        student: "student",
        teacher: "teacher",
        business_man: "business-man",
        employee: "employe",
        colaborator: "collaborator",
        //Home
        welcome: "Welcome, dear ",
        titleAfterWelcome: "Welcome to our training and upskilling platform dedicated to employees and ambitious professionals. Whether you want to develop new skills, deepen your current knowledge, or prepare for new opportunities, we have the resources and courses you need.",
        search: "Search courses",
        hi: "Hi",
        youHave: "You have",
        newEvent: "new events",
        viewAllEvent: "View all events",
        dashboard: "Dashboard",
        chargeAccount: "Charge account",
        titreCarousel1: "Regular Course Monitoring",
        descriptioCarousel1: "Keep track of your progress effortlessly with our platform's regular course monitoring. Stay on top of your learning goals with timely reminders and updates.",
        titreCarousel2: "Diverse Course Content",
        descriptioCarousel2: "Our courses offer a rich blend of video lectures, textual materials, and images to enhance your learning experience. Engage with content in multiple formats for a comprehensive understanding.",
        titreCarousel3: "Certification Upon Completion",
        descriptioCarousel3: "Earn a recognized certification upon successful completion of your course. Showcase your new skills and knowledge to potential employers.",
        //prof homecard 
        balance: "Account Balance",
        purchased: "Purchased Courses",
        meeting: "Meetings",
        yourStudent: "Yours Students",
        //Cours
        yaCourse: "Your courses",
        interestingCourse: "Interresting courses",
        in: "In",
        hour: "hours",
        followed: "Followed",
        //Details cours
        createdBy: "Created by",
        yllLearn: "What will you learn ? ",
        aboutCourse: "About this course",
        addCard: "Add to card",
        buy: "Buy now !",
        payInstallment: "Pay in installments",
        //Footer
        AboutUs: "About us",
        privacyPolicy: "Privacy Policy",
        condition: "Term  of Use",
        plan: "Plan of the site",
        droitDauteur: "All rights reserved",
        explore: "Explore, grow, learn more",
        receiveThe: "Receive the",
        ourDomain: "Our domain",
        ourAdress: "Our adress",
        sendEmail: "Send Email",
        //Contact
        infoContact: "Contact information",
        //Input 
        name: "Name",
        phone: "Phone",
        subject: "Subject",
        sendMessage: "Send Message",
        questionContact: "Do you have a big idea or a brand to develop and need help?",
        //Auth 
        welcomeAuth: "Welcome",
        WantVisited: "Would you like to visit our platform?",
        clickHere: "Click here",
        EmailAdress: "Email adress",
        password: "Password",
        ForgotPswd: "Forgot password ?",
        SingUp: "Sing up",
        NoAccount: "Don't have an account yet?",
        //Inscription 
        createAccount: "Create Account",
        ConfirmaPswd: "Confirm Password",
        student: "Student",
        teacher: "Teacher",
        HaveAccount: "Do you already have an account?",
        SingIn: "Sing in",
        //Certificat
        certificate: "Certificate",
    }
};

function translate(language, key) {
    return translations[language][key];
}

export default translate;
