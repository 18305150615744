import React, { useState } from "react";
import { OSSelect } from "../../../Components/Input/OSSelect";
import { OSInput } from "../../../Components/Input/OSInput";
import { LoginInput } from "../../../Components/Input/LoginInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { OSButton } from "../../../Components/Button/OSButton";


const course = [
    {
        id: 1,
        nom: "Cours 1",
        chapitre: [
            {
                title: "chapite 1 1",
            },
            {
                title: "chapite 2 1",
            },
            {
                title: "chapite 3 1",
            }
        ],
    },
    {
        id: 2,
        nom: "Cours 2",
        chapitre: [
            {
                title: "chapite 1 2",
            },
            {
                title: "chapite 2 2",
            },
            {
                title: "chapite 3 2",
            }
        ],
    },
    {
        id: 3,
        nom: "cours 3",
        chapitre: [
            {
                title: "chapite 1 3 ",
            },
            {
                title: "chapite 2 3",
            },
            {
                title: "chapite 3 3",
            }
        ],
    },
]

export const ModificationExercice = () => {
    const [cours, setCours] = useState([])
    const [selectedCours, setSelectedCours] = useState()
    const [chapitre, setChapitre] = useState(course.flatMap(c => c.chapitre))
    const [selectedChapitre, setSelectedChapitre] = useState()
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([""]);
    const [correctAnswer, setCorrectAnswer] = useState('');


    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    };

    const handleIconClick = (index) => {
        setAnswers((prevAnswers) => prevAnswers.filter((_, i) => i !== index));
    };

    const addAnswer = () => {
        setAnswers([...answers, ""]);
    };

    const handleCoursChange = async (selectedValue) => {
        setSelectedCours(selectedValue.target.value)
        const newSelectedChapitre = course.filter(cour => cour.id == selectedValue.target.value)
        setChapitre(newSelectedChapitre.flatMap(c => c.chapitre))
    }

    const handleChapitreChange = (selectedValue) => {
        setSelectedChapitre(selectedValue.target.value)
    }


    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div
                className="bg-white rounded-lg shadow-lg w-1/3"
            >
                <div className="p-5">
                    <OSSelect label="Cours" id='cours' items={course} onChange={handleCoursChange} />
                    <OSSelect label="Cours" id='cours' items={chapitre} onChange={handleChapitreChange} /> <br />
                    <OSInput
                        placeholder="Entrez la question"
                        state={handleQuestionChange}
                    />
                    <OSInput
                        placeholder="Entrez la réponse correcte"
                        state={(e) => setCorrectAnswer(e.target.value)}
                    />
                    {answers.map((p, index) => (
                        <LoginInput
                            key={index}
                            icon={faTrash}
                            id={index}
                            placeholder="Entrez une réponse"
                            onIconClick={() => handleIconClick(index)}
                            state={(e) => {
                                const newAnswers = [...answers];
                                newAnswers[index] = e.target.value;
                                setAnswers(newAnswers);
                            }}
                        />
                    ))}
                </div>
                <div className="flex justify-around p-5 border-t">
                    <button
                        onClick={addAnswer}
                        className="text-gray-700 py-2 px-4 rounded-md transition duration-300"
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <OSButton value="Modifier" />
                </div>
            </div>
        </div>
    )
}