import { faAdd } from '@fortawesome/free-solid-svg-icons/faAdd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { OSInput } from '../../../Components/Input/OSInput';

const AnswerInput = () => {
    const [answer, setAnswer] = useState('');

    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
    };

    const onRemove = () => {
        const element = document.getElementById(`answer-form`);
        if (element) {
            element.remove();
        }
    };

    return (
        <div
            id='answer-form'
            className="flex items-center pt-3 justify-around w-full bg-white rounded-lg shadow-sm mb-3"
        >
            <OSInput
                placeholder="Entrez une réponse"
                state={handleAnswerChange}
                value={answer}
                className="mr-3"
            />
            <button
                onClick={onRemove}
                className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded-full transition duration-300"
            >
                Supprimer
            </button>
        </div>
    );
};

const QuestionForm = () => {
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState('');

    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    };

    const addAnswer = () => {
        setAnswers(answers + 1);
    };

    const onRemove = () => {
        const element = document.getElementById(`question-form`);
        if (element) {
            element.remove();
        }
    };

    return (
        <div id='question-form' className="my-6 p-6 bg-white border border-gray-300 rounded-lg shadow-lg md:w-2/3">
            <OSInput
                placeholder="Entrez la question"
                state={handleQuestionChange}
                className="w-full mb-4"
            />
            <AnswerInput />
            {[...Array(answers)].map((_, index) => (
                <AnswerInput key={index} id={index} />
            ))}
            <OSInput
                placeholder="Entrez la réponse correcte"
                state={(e) => setCorrectAnswer(e.target.value)}
                className="w-full mb-4"
            />
            <div className="flex justify-between">
                <button
                    onClick={addAnswer}
                    className="bg-primary hover:bg-green-600 text-white py-2 px-4 rounded-full transition duration-300"
                >
                    Ajouter une réponse
                </button>
                <button
                    onClick={onRemove}
                    className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full transition duration-300"
                >
                    Supprimer la question
                </button>
            </div>
        </div>
    );
};

export const CreerExamen = () => {
    const [question, setQuestion] = useState([""]);

    // const addQuestionForm = () => {
    //     setQuestionCount(questionCount + 1);
    // };

    return (
        <div className="mt-5 w-full flex flex-col items-center p-4">
            <div className='w-full fixed flex flex-col items-end'>
                <button
                    onClick={(e) => {
                        setQuestion([...question, ""])
                    }}
                    className="mb-6 flex items-center justify-center bg-primary hover:bg-green-600 text-white py-2 px-4 rounded-full shadow-lg transition duration-300"
                >
                    <FontAwesomeIcon icon={faAdd} className="mr-2" />
                    Ajouter une question
                </button>
            </div>
            <div className='w-1/2 flex flex-col items-center'>
                <QuestionForm />
            </div>
            {question.map((q, index) => (
                <div key={index} id={`question-form-${index}`} className='w-1/2 flex flex-col items-center'>
                    <QuestionForm />
                </div>
            ))}
        </div>
    );
};

export default CreerExamen;
