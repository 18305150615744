import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../Lottie/NotFoundAnimation.json';

export const AucunElement = ({ item }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className='w-full flex flex-col justify-center items-center'>
            <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
            <div>
                <p className='text-xl text-black/80'>Aucun {item} trouvés!</p>
            </div>
        </div>
    )
}