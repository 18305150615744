import React, { useEffect, useState } from "react";
import { OSInput } from "../../Components/Input/OSInput";
import { OSButton } from "../../Components/Button/OSButton";
import { OSSelect } from "../../Components/Input/OSSelect";
import { TextAreaInput } from "../../Components/Input/TextareaInput";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../store/Api/Intercepteur";

export const ModificationCours = () => {
    const location = useLocation();
    const selectedItem = location.state.selectedItem;
    const [nomCours, setNomCours] = useState(selectedItem.nom);
    const [Categorie, setCategorie] = useState([]);
    const [selectedCategorie, setSelectedCategorie] = useState(selectedItem.id_categorie);
    const [editorValue, setEditorValue] = useState(selectedItem.contenue);
    const [description, setDescription] = useState(selectedItem.description);
    const [cout, setCout] = useState(selectedItem.cout)
    const navigate = useNavigate()
    const [photo, setPhoto] = useState(`http://192.168.1.139:8000/storage/${selectedItem.image}`);

    const fetchCategorie = async () => {
        try {
            const response = await api.get(`/categories`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setCategorie(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCategorie();
        DecoupledEditor
            .create(document.querySelector('#editor'), {
                initialData: selectedItem.details || ""
            })
            .then(editor => {
                const toolbarContainer = document.querySelector('#toolbar-container');
                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                editor.model.document.on('change:data', () => {
                    const data = editor.getData();
                    setEditorValue(data);
                });
            })
            .catch(error => {
                console.error(error);
            });
    }, [selectedItem]);

    const ModifierClicked = async (event) => {
        event.preventDefault();
        try {
            const response = await api.put(`/cours/${selectedItem.id}`, {
                nom: nomCours, id_categorie: selectedCategorie,
                description: description, details: editorValue, cout: cout
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                setNomCours('');
                setDescription('');
                setEditorValue('');
                setCout('');
                navigate('/cours', { state: { showNotification: true } });
            } else {
                throw new Error('Erreur lors de la modification du cours');
            }
        } catch (error) {
            console.error('Erreur:', error);
        }
    };


    const AnnulerHandle = () => {
        navigate('/cours')
    }

    return (
        <div className="ml-72 mt-16 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Modification cours</p>
            </div>
            <form onSubmit={ModifierClicked} className="rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div className="w-2/3">
                    <OSInput placeholder="Titre" id="nom" defaultValue={nomCours} state={((e) => setNomCours(e.target.value))} />
                    <OSInput placeholder="cout" id="cout" defaultValue={cout} state={(e) => setCout(e.target.value)} />
                    <p className="text-gray-500">Categorie :</p>
                    <OSSelect id="Categorie" items={Categorie} onChange={((e) => setSelectedCategorie(e.target.value))} />
                    <TextAreaInput label="Description du cours" defaultValue={description} id="description" onChange={((e) => setDescription(e.target.value))} />
                </div>
                <div>
                    <h2 className="text-gray-700">Ce qu'on va apprendre : </h2>
                    <div id="toolbar-container"></div>
                    <div id="editor" className="min-h-40 border max-h-96 h-auto"></div>
                </div>
                <div className="flex justify-between items-center mt-16">
                    <button onClick={AnnulerHandle} className="text-light bg-red-600 font-medium rounded-xl w-40 text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Modifier" />
                </div>
            </form>
        </div>
    );
};
