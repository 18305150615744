import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OSInput } from "../../Input/OSInput";
import { OSButton } from "../../Button/OSButton";
import api from "../../../store/Api/Intercepteur";

export const CategorieCardAdmin = ({ isButton, items, onRefresh }) => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null);
    const modalRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [nomCategorie, setNomCategirie] = useState('')
    const [id, setId] = useState(null)

    const handleEditClick = (categorie, id) => {
        setNomCategirie(categorie);
        setIsModalOpen(true)
        setId(id)
    };

    const handleDeleteClicked = async (id) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer ce categorie");
        let response = false;
        if (confirmation) {
            try {
                await api.delete(`/categories/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
                onRefresh()
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const AjouterClicked = async (e) => {
        e.preventDefault()
        try {
            await api.post("/categories",
                {
                    nom: nomCategorie
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
            setIsModalOpen(false)
            setNomCategirie('')
            onRefresh()
        } catch (error) {
            console.log(error)
        }
    }

    const ModifierClicked = async (e) => {
        e.preventDefault()
        try {
            await api.put(`/categories/${id}`,
                {
                    nom: nomCategorie
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
            setIsModalOpen(false)
            setNomCategirie('')
            onRefresh()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="w-full px-5">
            <div className="w-full">
                {!isButton ? (
                    <div className=" w-full">
                        <table className="table table-auto table-border w-full" data-datatable-table="true">
                            <thead>
                                <tr className="text-blue font-bold h-10 border-b">
                                    <th className="text-left pl-5">
                                        <span className="sort">
                                            <span className="sort-label">
                                                Categorie
                                            </span>
                                            <span className="sort-icon">
                                            </span>
                                        </span>
                                    </th>
                                    <th className="w-32">
                                        <span className="sort">
                                            <span className="sort-label">
                                                Action
                                            </span>
                                            <span className="sort-icon">
                                            </span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index} className={`border-b h-10 ${index % 2 !== 0 ? '' : 'bg-gray-100'}`}>
                                        <td className="drop-shadow-2xl font-semibold text-black/80 pl-5">{item.nom}</td>
                                        <td className='text-center'>
                                            <button
                                                className="text-green-700/80 mr-5"
                                                onClick={() => handleEditClick(item.nom, item.id)}
                                            >
                                                <FontAwesomeIcon icon={faEdit} color="green" />
                                            </button>
                                            <button
                                                className="text-green-700/80"
                                                onClick={() => handleDeleteClicked(item.id)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="red" width={16} height={16}>
                                                    <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                ) : (
                    <div className="flex justify-end mr-1 mt-3 mb-5">
                        <button onClick={() => setIsModalOpen(true)} className="flex items-center justify-around hover:bg-green-100 bg-gray-200 px-6 rounded-lg py-2">
                            <FontAwesomeIcon icon={faPlusCircle} className="text-gray-700 mr-2" />
                            <p className="text-gray-700">Nouveau categorie</p>
                        </button>
                    </div>
                )}
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div
                        ref={modalRef}
                        className="bg-white rounded-lg py-3 shadow-md w-4/5 max-w-lg dark:bg-white"
                    >
                        <button onClick={() => setIsModalOpen(false)} className="w-full text-right pr-5 pb-3">
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <form onSubmit={id ? ModifierClicked : AjouterClicked} className=" px-8 flex flex-col justify-center" >
                            <div>
                                <OSInput placeholder="Nom categorie" id="nom" defaultValue={nomCategorie} state={((e) => setNomCategirie(e.target.value))} />
                            </div>
                            <div className="flex justify-between items-center">
                                <OSButton value={`${id ? "Modifier" : "Ajouter"}`} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div >
    );
};
