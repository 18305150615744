import React, { useEffect, useState } from "react";
import { LoginInput } from "../../Components/Input/LoginInput";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { LoginPswrdInput } from "../../Components/Input/LoginPswrdInput";
import { Link, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/User/user";
import { NotificationCard } from "../../Components/Notification/NotificationCard";
import { toast, ToastContainer } from "react-toastify";
import { UseLanguage } from "../../store/User/language";
import translate from "../../store/Translation/Langue";

const selectedOption = "bg-thirdly text-white "

export const Authentification = () => {
    const [Email, setEmail] = useState("");
    const [motDePasse, setMotDePasse] = useState("");
    const login = useAppStore.use.login();
    const navigate = useNavigate();
    const [showNotification, setshowNotification] = useState(false)
    const [errorsEmail, setErrorsEmail] = useState();
    const [errorsPswrd, setErrorsPswrd] = useState();
    const language = UseLanguage.use.language()

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Email) {
            if (!/\S+@\S+\.\S+/.test(Email)) {
                return setErrorsEmail("Veuillez entrer une adresse e-mail valide.")
            }
            else {
                setErrorsEmail("")
            }
        }
        else {
            return setErrorsEmail("Veuillez entrer votre adresse e-mail.")
        }

        if (motDePasse) {
            if (motDePasse.length < 8) {
                return setErrorsPswrd("Le mot de passe doit contenir au moins 8 caractères.")
            }
            else {
                setMotDePasse("")
            }
        }
        else {
            return setErrorsPswrd("Veuillez entrer votre mot de passe.")
        }

        const result = await login(Email, motDePasse);
        if (result.success) {
            navigate(`/`);
        } else {
            if (result.status === 500) {
                toast.warning("Utilisateur non trouvé!")
            }
            console.log(result.error);
        }
    };

    return (
        <div className="relative flex items-center justify-center min-h-screen">
            <div
                className="absolute inset-0 bg-cover bg-center w-full min-h-screen"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="min-h-screen w-screen" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssvgjs="http://svgjs.dev/svgjs" preserveAspectRatio="none" viewBox="0 0 1440 560">
                    <g mask="url(&quot;#SvgjsMask3329&quot;)" fill="none">
                        <rect width="1440" height="560" x="0" y="0" fill="url(&quot;#SvgjsRadialGradient3330&quot;)"></rect>
                        <path d="M48.72 648.69C226.51 648.52 390.87 563.77 754.47 561.16 1118.07 558.55 1271.96 302.2 1460.22 297.96" stroke="rgba(104, 134, 218, 0.34)" strokeWidth="2"></path>
                        <path d="M455.43 606.94C554.17 597.22 516.74 395.83 785.94 387.21 1055.13 378.59 1274.2 187.83 1446.95 185.61" stroke="rgba(104, 134, 218, 0.34)" strokeWidth="2"></path>
                        <path d="M390.97 599.92C558.41 598.63 698.29 440 1039.99 434.5 1381.68 429 1513.98 176.24 1689 171.3" stroke="rgba(104, 134, 218, 0.34)" strokeWidth="2"></path>
                        <path d="M372.34 629.44C516.68 567.65 446.03 122.27 734.63 116.43 1023.24 110.59 1275.47 239.19 1459.22 239.63" stroke="rgba(104, 134, 218, 0.34)" strokeWidth="2"></path>
                        <path d="M539.81 583.04C696.76 575.32 790.49 315.28 1101.85 301.58 1413.21 287.88 1509.44 50.02 1663.89 43.98" stroke="rgba(104, 134, 218, 0.34)" strokeWidth="2"></path>
                    </g>
                    <defs>
                        <mask id="SvgjsMask3329">
                            <rect width="1440" height="560" fill="#ffffff"></rect>
                        </mask>
                        <radialGradient cx="100%" cy="50%" r="1466.97" gradientUnits="userSpaceOnUse" id="SvgjsRadialGradient3330">
                            <stop stopColor="rgba(142, 192, 255, 1)" offset="0"></stop>
                            <stop stopColor="rgba(144, 255, 181, 1)" offset="0.06"></stop>
                            <stop stopColor="rgba(240, 255, 240, 1)" offset="0.18"></stop>
                            <stop stopColor="rgba(244, 255, 242, 1)" offset="0.33"></stop>
                            <stop stopColor="rgba(255, 255, 255, 1)" offset="0.58"></stop>
                        </radialGradient>
                    </defs>
                </svg>
            </div>
            <div className="relative max-md:hidden w-[65%] text-black/80 h-72 flex pl-14 rounded-lg flex-col justify-center items-start bg-gray-100/20 shadow-cardShadow max-lg:w-[80%] max-lg:pl-10">
                <p className="text-3xl font-semibold w-80 max-lg:text-xl">{translate(language, "WantVisited")}</p>
                <Link to="/" className="border border-secondary py-1 px-7 mt-5 shadow-blue-500/20 rounded">{translate(language, "clickHere")}</Link>
            </div>
            <div className="absolute max-sm:static max-sm:right-0 z-30 right-64 py-5 px-2 h-auto bg-white w-auto shadow-2xl rounded max-lg:w-80 max-lg:right-36">
                <p className="text-gray-800 text-2xl mt-11 mb-3 w-full text-center font-semibold">{translate(language, "welcomeAuth")} !</p>
                <form onSubmit={handleSubmit} className="flex flex-col p-5 items-center justify-center">
                    <div className="w-full mb-2">
                        <LoginInput
                            icon={faUser}
                            id="user"
                            placeholder={translate(language, "EmailAdress")}
                            state={(e) => {
                                setEmail(e.target.value);
                            }}
                            error={errorsEmail}
                        />
                        <LoginPswrdInput
                            id="Mot_de_passe"
                            placeholder={translate(language, "password")}
                            state={(e) => {
                                setMotDePasse(e.target.value);
                            }}
                            error={errorsPswrd}
                        />
                    </div>
                    <Link to="/resetPassword" className="text-xs max-md:hidden hover:underline mr-7 text-gray-500">{translate(language, "ForgotPswd")}</Link>
                    <button type="submit" className="w-full py-2 px-4 mt-2 text-sm font-semibold rounded text-white bg-thirdly hover:bg-green-800 focus:outline-none">
                        Connexion
                    </button>
                </form>
                <div className="md:hidden flex mt-5 justify-between text-black/70 text-sm">
                    <Link to="/inscription">{translate(language, "SingUp")}</Link>
                    <Link to="/resetPassword">{translate(language, "ForgotPswd")}</Link>
                </div>
                <p className="text-sm max-md:hidden mt-6 px-3 text-center text-black/80">
                    {translate(language, "NoAccount")}
                    <Link to="/inscription" className="text-thirdly font-semibold hover:underline ml-1">{translate(language, "SingUp")}</Link>
                </p>
            </div>
            <ToastContainer />
        </div>
    );
};

