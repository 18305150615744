import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

// const socket = io('http://localhost:5000');

const MeetPage = ({ roomID }) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isVideoStarted, setIsVideoStarted] = useState(false);
    const [myStream, setMyStream] = useState(null);
    const [peers, setPeers] = useState({});
    const videoRef = useRef(null);

    // useEffect(() => {
    //     socket.emit('join_room', roomID);

    //     socket.on('user_connected', (userID) => {
    //         setUsers((prevUsers) => [...prevUsers, userID]);
    //     });

    //     socket.on('user_disconnected', (userID) => {
    //         setUsers((prevUsers) => prevUsers.filter((user) => user !== userID));
    //     });
    // }, []);

    // const startVideo = async () => {
    //     if (!selectedUser) return;

    //     setIsVideoStarted(true);
    //     const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    //     setMyStream(stream);
    //     videoRef.current.srcObject = stream;
    //     const peerConnection = createPeerConnection(selectedUser);
    //     const offer = await peerConnection.createOffer();
    //     await peerConnection.setLocalDescription(offer);
    //     socket.emit('send_offer', { offer, roomID, userID: selectedUser });
    // };

    // const createPeerConnection = (userID) => {
    //     const peerConnection = new RTCPeerConnection({
    //         iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    //     });

    //     peerConnection.onicecandidate = (event) => {
    //         if (event.candidate) {
    //             socket.emit('send_ice_candidate', { candidate: event.candidate, roomID, userID });
    //         }
    //     };

    //     peerConnection.ontrack = (event) => {
    //         const remoteVideo = document.createElement('video');
    //         remoteVideo.srcObject = event.streams[0];
    //         remoteVideo.autoplay = true;
    //         document.body.append(remoteVideo);
    //     };

    //     myStream.getTracks().forEach((track) => peerConnection.addTrack(track, myStream));

    //     setPeers((prevPeers) => ({ ...prevPeers, [userID]: peerConnection }));

    //     return peerConnection;
    // };

    return (
        <div className='min-h-screen ml-72 mt-20'>
            <h3>Utilisateurs connectés :</h3>
            <ul>
                {users.map((user) => (
                    <li key={user} onClick={() => setSelectedUser(user)}>
                        Utilisateur {user}
                    </li>
                ))}
            </ul>
            {/* {selectedUser && !isVideoStarted && (
                <button onClick={startVideo}>Démarrer la visioconférence</button>
            )} */}
            <video ref={videoRef} autoPlay playsInline muted />
        </div>
    );
};

export default MeetPage;
