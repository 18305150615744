import { useEffect, useState } from "react";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import classNames from "classnames";
import Modal from "react-modal";

Modal.setAppElement("#root");

export const PasserExamenCard = ({ examen }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [timer, setTimer] = useState(600);
    const [responses, setResponses] = useState([]);
    const [showModal, setShowModal] = useState(false)

    const handleNotificationClose = () => {
        setShowModal(false);
    };

    const currentQuestion = examen[currentQuestionIndex];

    useEffect(() => {
        if (timer <= 0) {
            alert("Time's up! The exam is now finished.");
            handleFinish();
        } else {
            const intervalId = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timer]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes} min ${seconds < 10 ? '0' : ''}${seconds}s`;
    };

    const handleOptionChange = (e) => {
        const selected = parseInt(e.target.value);
        setSelectedOption(selected);
    };

    const getLabelClasses = (index) => {
        if (index === selectedOption) {
            return "bg-green-200 border-green-400 ";
        }
    };

    const nextQuestion = () => {
        setResponses((prevResponses) => [
            ...prevResponses,
            { questionId: currentQuestion.id, responseId: selectedOption },
        ]);

        if (currentQuestionIndex < examen.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedOption(null);
        } else {
            handleFinish();
        }
    };

    const handleFinish = () => {
        console.log("Exam finished");
        console.log("Responses:", responses);
        setShowModal(true)
    };

    if (!examen || examen.length === 0) {
        return <LoadingScreen />;
    }

    return (
        <div className="h-full w-[400px] mt-4 text-gray-500 flex flex-col justify-center items-center px-5 shadow-cardShadow rounded-lg pt-3 ">
            <div className="flex justify-between w-full font-medium py-3">
                <div>
                    <p>Get as many answers as possible before <span className="text-black/80 font-bold">{formatTime(timer)}</span></p>
                </div>
            </div>
            <div className="relative block w-full p-5 rounded-lg bg-neutral-50 shadow-secondary-1 border border-gray-200 pt-3 mb-5 min-h-52">
                <div className="relative border-b px-6 py-3 font-semibold">
                    <p>Question {currentQuestionIndex + 1} of {examen.length}</p>
                </div>
                <div className="flex justify-between bg-white items-center py-3">
                    <p className="text-xl text-black/70 font-bold">{currentQuestion.question}</p>
                </div>
                <div className="flex flex-col items-center">
                    {currentQuestion.reponses?.map((response, index) => (
                        <div className="mt-3 w-full flex items-center justify-center" key={index}>
                            <input
                                id={response.id}
                                type="radio"
                                name="response"
                                value={index}
                                onChange={handleOptionChange}
                                checked={selectedOption === index}
                                className="peer hidden"
                            />
                            <p>{index + 1}</p>
                            <label
                                htmlFor={response.id}
                                className={`${getLabelClasses(index)} border font-semibold w-4/5 ml-2 rounded-lg px-4 py-2`}
                            >
                                {response.contenue}
                            </label>
                        </div>
                    ))}
                </div>
                <div className={`w-full flex justify-end p-3 border-t border-gray-200 mt-5 text-black/70`}>
                    <button
                        className={classNames("flex items-center text-white bg-black/60 rounded-lg p-2 ml-3 font-bold", { "opacity-50 cursor-not-allowed": selectedOption === null })}
                        onClick={currentQuestionIndex === examen.length - 1 ? handleFinish : nextQuestion}
                        disabled={selectedOption === null}
                    >
                        <p>{currentQuestionIndex === examen.length - 1 ? "Finish" : "Next question"}</p>
                    </button>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={handleNotificationClose}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                <div className="bg-white rounded-lg shadow-lg w-1/3 p-6 transform transition-all duration-300 ease-in-out">
                    <h1 className="font-bold text-black/80 text-4xl">Chapitre</h1>
                    <p className="text-black/70">Let's take a look at your scores</p>
                    <p className="my-5 text-8xl text-primary text-center font-semibold">4</p>
                    <p className="text-center text-primary font-semibold">correct</p>
                    <p>...and <span>1 incorrect</span></p>
                    <button>Retourner a vos cours</button>
                </div>
            </Modal>
        </div>
    );
};
