import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const SelectionCoursSuivi = ({ chapitre, part }) => {
    const [selectedPart, setSelectedPart] = useState(false)
    const selection = () => {
        setSelectedPart(!selectedPart)
    }

    return (
        <div className={`w-full h-full flex flex-col items-center justify-center  ${selectedPart ? 'bg-gray-100 border-b border-gray-200 rounded-md' : ''}`}>
            <button onClick={selection} className={`pl-2 flex justify-between items-center w-full rounded-md p-1 hover:bg-gray-200 hover:rounded-md ${selectedPart ? 'rounded-none border-b border-gray-300' : ""}`}>
                <p className="text-black/80 text-sm">{chapitre}</p>
                <FontAwesomeIcon icon={faChevronRight} className={`w-3 h-2 text-black/80 ${selectedPart ? 'transition-all duration-500 linear rotate-90' : "transition-all duration-500 linear"}`} />
            </button>
            <div className="transition-all duration-500 linear w-full overflow-hidden flex flex-col justify-center" style={{
                height: selectedPart ? `calc(32px * ${part.length})` : '0px'
            }}>
                {part?.map((p, index) => (
                    <div className="text-black/90 w-full flex text-sm" key={index}>
                        <Link to="/cours/view-cours" className="w-full hover:bg-gray-200 p-1 pl-11 text-sm">{p.title}</Link>
                    </div>
                ))}
            </div>
        </div>
    )
}