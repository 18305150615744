import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const CheckBoxInput = ({ value, name, onChange, label, checked }) => {
    return (
        <div role="button"
            className="flex items-center w-full p-0 my-1 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
            <label htmlFor={value} className="flex justify-between items-center w-full px-3 py-2 cursor-pointer">
                <label htmlFor={value} className="block cursor-pointer  antialiased leading-relaxed text-black/80">
                    {label}
                </label>
                <div className="grid place-items-center">
                    <div className="inline-flex items-center justify-center">
                        <label className="relative flex items-center justify-center p-0 rounded cursor-pointer"
                            htmlFor={value}>
                            <input
                                type="checkbox"
                                id={value}
                                value={value}
                                name={name}
                                onChange={onChange}
                                checked={checked}
                                className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded border-2 border-gray-400 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-400 checked:before:bg-gray-400 hover:before:opacity-0 checked:border-none checked:bg-secondary"
                            />
                            <FontAwesomeIcon icon={faCheck} className="absolute text-primary transition-opacity opacity-0 pointer-events-none text-center peer-checked:opacity-100 peer-checked:text-white" />
                        </label>
                    </div>
                </div>
            </label>
        </div>
    );
};
