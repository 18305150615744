import React, { useEffect, useState } from "react";
import { OSInput } from "../../Components/Input/OSInput";
import { OSButton } from "../../Components/Button/OSButton";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../store/Api/Intercepteur";

export const ModificationChapitre = () => {
    const location = useLocation()
    const items = location.state.items
    const chapitre = location.state.chapitre
    const [Titre, setTitre] = useState(chapitre.titre || "");
    const [editorValue, setEditorValue] = useState("");
    const navigation = useNavigate();


    const initializeEditor = () => {
        DecoupledEditor
            .create(document.querySelector('#editor'), {
                initialData: chapitre.contenues || ""
            })
            .then(editor => {
                const toolbarContainer = document.querySelector('#toolbar-container');
                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                editor.model.document.on('change:data', () => {
                    const data = editor.getData();
                    setEditorValue(data);
                });
            })
            .catch(error => {
                console.error(error);
            });
    }

    useEffect(() => {
        initializeEditor();
    }, []);

    const AjouterClicked = async (event) => {
        event.preventDefault();
        try {
            await api.put(`/chapitres/${chapitre.id}`,
                {
                    titre: Titre,
                    contenues: editorValue,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
            navigation(`/Chapitre/view-chapitre`, { state: { items, idchapitre: chapitre.id } });
        } catch (error) {
            console.log(error);
        }
    }
    const AnnulerClicked = (event) => {
        event.preventDefault();
        setTitre('')
        setEditorValue('')
        navigation(`/Chapitre/view-chapitre`, { state: { items, idchapitre: chapitre.id } });
    }

    return (
        <div className="ml-72 mt-16 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-secondaryblue font-bold">Modification Chapitre</p>
            </div>
            <form onSubmit={AjouterClicked} className="rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200" >
                <OSInput placeholder="Titre" id="titre" defaultValue={Titre} state={((e) => setTitre(e.target.value))} />
                <div>
                    <h2 className="text-gray-700">Contenu du chapitre : </h2>
                    <div id="toolbar-container"></div>
                    <div id="editor" className="border min-h-96 h-auto">
                    </div>
                </div>
                <div className="flex justify-between items-center mt-16">
                    <button onClick={AnnulerClicked} className="text-light bg-red-600 font-medium rounded-xl w-40 text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Ajouter" />
                </div>
            </form>
        </div>
    );
};
