import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import Modal from "react-modal";
import { Auth } from "../../../Pages/Authentification/Auth";
import { useAppStore } from "../../../store/User/user";
import translate from "../../../store/Translation/Langue";
import { UseLanguage } from "../../../store/User/language";
import { baseURL, baseUrlImage } from "../../../store/Api/Intercepteur";

Modal.setAppElement("#root");

export const CoursCard = ({ items, isSuivi }) => {
    const type = useAppStore.use.type()
    const [showModal, setShowModal] = useState(false);
    const language = UseLanguage.use.language()
    const navigate = useNavigate()

    const handleNotificationOpen = () => {
        setShowModal(true);
    };

    const handleNotificationClose = () => {
        setShowModal(false);
    };

    const DetailsCoursClicked = (selectedItem, isSuivi) => {
        navigate('/cours/detail-cours', { state: { selectedItem, isSuivi } })
    }

    return (
        <div className="max-sm:w-full">
            <div className="relative rounded-2xl h-[520px] bg-white border border-gray-200 w-80 mb-10 shadow-md hover:-translate-y-3 transition duration-500 max-md:w-full">
                <button onClick={() => DetailsCoursClicked(items, isSuivi)} className="max-md:w-full">
                    <div className="absolute flex flex-col justify-center items-center bg-primary px-4 py-1 text-white hover:bg-secondary hover:text-blue-950 mt-2 ml-2 rounded-br-2xl rounded-tl-2xl">
                        <p>{translate(language, "courses")}</p>
                    </div>
                    <div className="w-80 max-sm:w-full">
                        <img src={items.image ? `${baseUrlImage}/storage/${items.image}` : "https://lms.rocket-soft.org/store/867/How%20to%20Travel%20Around%20the%20World%20on%20a%20Budget.jpg"} alt="images" className="object-cover w-80 h-60 max-md:w-full rounded-t-2xl" />
                    </div>
                    <div className="mt-3 px-5 flex items-center">
                        <img src="https://lms.rocket-soft.org/store/929/avatar/617a4f5d834c8.png" alt="user" className="rounded-full w-11 h-11" />
                        <Link to="/profile" className="ml-3 text-[#343a40]">{items.enseignant}</Link>
                    </div>
                    <div className="flex flex-col justify-between px-5">
                        <div className="w-full text-left mt-3">
                            <Link to="/cours/view-cours" className="text-blue font-bold text-xl">{items.nom}</Link>
                        </div>
                        <span className="text-black/70 mt-5 w-full text-left">
                            <span>{translate(language, "in")} </span>
                            <Link to="/matiere/view-matiere" className="underline decoration-gray-700/80">{items.categorie}</Link>
                        </span>
                        <div className="flex justify-between mt-5">
                            <div>
                                <FontAwesomeIcon icon={faClock} className="text-blue-950 mr-2" />
                                <span className="text-blue-950">50 {translate(language, "hour")}</span>
                            </div>
                            <span className="text-blue-950 flex justify-between items-center">
                                <FontAwesomeIcon icon={faCalendar} className="text-blue-950 mr-2" />
                                <span>05/06/2024</span>
                            </span>
                        </div>
                    </div>
                    <div className="p-3 my-3">
                        {isSuivi ? (
                            <div>
                                <p className="text-secondary font-extrabold text-xl">{translate(language, "followed")}</p>
                            </div>
                        ) : (
                            <div>
                                <p className="text-secondary font-extrabold text-xl">${items.cout}</p>
                            </div>
                        )}
                    </div>
                </button>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={handleNotificationClose}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                {type === "" ? (
                    <Auth handleCloseModal={handleNotificationClose} />
                ) : (
                    <div className="bg-white rounded-lg shadow-lg w-1/3 p-6 transform transition-all duration-300 ease-in-out">
                        <div className="flex flex-col items-center gap-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-16 w-16 text-red-500"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <h4 className="text-red-500 text-2xl font-semibold">Débloquez ce cours</h4>
                            <p className="text-center font-normal">
                                Vous êtes sur le point de débloquer ce cours en utilisant votre crédit. Voulez-vous continuer ?
                            </p>
                            <div className="flex gap-2 mt-4 w-full justify-between">
                                <button className="text-white py-2 px-4 rounded-md bg-gradient-to-r from-red-400 to-red-500" onClick={handleNotificationClose}>Annuler</button>
                                <button className="bg-gradient-to-r from-secondary to-primary text-white py-2 px-4 rounded" onClick={handleNotificationClose}>Oui, débloquer</button>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};
