import axios from 'axios';

// export const baseURL = 'http://192.168.1.139:8000/api'
// export const baseUrlImage = 'http://192.168.1.139:8000'
export const baseURL = 'http://91.134.91.43:8000/api'
export const baseUrlImage = "http://91.134.91.43:8000"

const api = axios.create({
    baseURL: `${baseURL}`,
});

// api.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => Promise.reject(error)
// );

export default api
