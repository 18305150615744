import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectionParts from "../../Components/Cards/Visiteur/SelectionPart";
import { useAppStore } from "../../store/User/user";
import ViewChapitre from "../chapitre/ViewChapitre";
import { FiltreChapitre } from "../../Components/Filtre/FiltreChapitre";
import { baseUrlImage } from "../../store/Api/Intercepteur";

export const ViewCours = () => {
    const location = useLocation();
    const selectedItem = location.state.selectedItem;
    const navigate = useNavigate()
    const [showDetails, setShowDetails] = useState(false)
    const type = useAppStore.use.type()

    const nouveauChapitre = () => {
        navigate('/Chapitre/Nouveau-Chapitre', { state: { selectedItem } })
    }

    const voirMaintenant = () => {
        navigate('/cours/detail-cours', { state: { selectedItem } })
    }

    return (
        <div className="w-full">
            {
                type === "enseignant" ? (
                    <div className="flex justify-between pr-5">
                        <div className="mt-7 rounded shadow-md border ml-64 mr-2 relative flex flex-col items-center w-[62vw]">
                            <div className="flex mt-20 w-3/4 h-full shadow mb-20">
                                <div className="h-96 w-60 rounded-lg">
                                    <img src={`${baseUrlImage}/storage/${selectedItem.image}`} alt="" className="h-96 w-60 object-cover rounded-lg" />
                                </div>
                                <div className={`ml-10 flex flex-col h-96 pb-8 w-[70%] ${showDetails ? "justify-start" : "justify-end "}`}>
                                    <p className="text-3xl font-semibold text-secondaryblue">{selectedItem.nom}</p>
                                    <div className="flex my-3">
                                        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#22c55e" stroke-width="2" aria-hidden="true" stroke-linecap="round" stroke-linejoin="round" class="icon">
                                            <g>
                                                <line x1="8" y1="6" x2="21" y2="6"></line>
                                                <line x1="8" y1="12" x2="21" y2="12"></line>
                                                <line x1="8" y1="18" x2="21" y2="18"></line>
                                                <line x1="3" y1="6" x2="3" y2="6"></line>
                                                <line x1="3" y1="12" x2="3" y2="12"></line>
                                                <line x1="3" y1="18" x2="3" y2="18"></line>
                                            </g>
                                        </svg>
                                        <p className="ml-1 text-black/60 text-base font-bold">{selectedItem.chapitres.length} chapitres</p>
                                    </div >
                                    <div>
                                        <p
                                            className={`text-black/60 my-3 overflow-hidden transition-all duration-300 ease-in-out`}
                                            style={{
                                                maxHeight: showDetails ? "1000px" : "4.5em",
                                            }}
                                        >
                                            {selectedItem.description}
                                        </p>
                                    </div>
                                    <div className="flex justify-between w-full items-center font-bold drop-shadow-2xl mt-3">
                                        <p className="text-secondary">${selectedItem.cout}</p>
                                        <button onClick={() => setShowDetails(!showDetails)} className="flex w-full justify-end items-center pr-5 text-[#222] font-bold">
                                            <p className="hover:underline">Plus de details</p>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#222222" stroke-width="2" aria-hidden="true" stroke-linecap="round" stroke-linejoin="round" class="">
                                                <g><polyline points="9 18 15 12 9 6"></polyline></g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="w-3/4 p-10 rounded-lg shadow-shadows mt-9">
                                <div className="flex flex-col items-center mt-3 w-full">
                                    {selectedItem.chapitres?.map(c => (
                                        <SelectionParts key={c.id} chapitre={c.titre} items={selectedItem.chapitres} />
                                    ))}
                                </div>
                                <div className="flex w-full justify-center mt-5">
                                    <button onClick={nouveauChapitre} className="text-white flex font-bold bg-primary hover:bg-secondary px-7 py-2.5 rounded-full shadow-md">
                                        <p className="border-r-2 pr-3">Nouveau chapitre</p>
                                        <FontAwesomeIcon icon={faPlus} className="w-6 h-6 ml-2 text-white" />
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        <div className="fixed w-60 top-[82px] px-3 right-4 shadow-md border h-[90vh] pb-7 rounded flex flex-col justify-between">
                            <div className="">
                                <p className="w-full text-center pt-4 text-black/80 font-semibold">{selectedItem.nom} </p>
                                {
                                    selectedItem.chapitres?.map(c => (
                                        <SelectionParts key={c.id} chapitre={c.titre} items={selectedItem.chapitres} idchapitre={c.id} />
                                    ))
                                }
                            </div>
                            <div className="flex w-full justify-center mb-5">
                                <button onClick={nouveauChapitre} className="text-white flex font-bold bg-primary hover:bg-secondary px-7 py-2.5 rounded-full shadow-md">
                                    <p className="">Nouveau chapitre</p>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <ViewChapitre selectedItem={selectedItem} />
                    </div>
                )
            }
        </div>
    );
};
