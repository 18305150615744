import React, { useEffect, useRef, useState } from "react";
import { faEdit, faEllipsisVertical, faPen, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OSInput } from "../../Input/OSInput";
import { LoginInput } from "../../Input/LoginInput";
import Modal from 'react-modal';
import { OSSelect } from "../../Input/OSSelect";
import { Link } from "react-router-dom";
import api from "../../../store/Api/Intercepteur";

Modal.setAppElement('#root');

export const ExamenCard = ({ isButton, item }) => {
    const [selected, setSelected] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [cours, setCours] = useState([]);
    const [selectedCours, setSelectedCours] = useState();
    const [selectedChapitre, setSelectedChapitre] = useState();
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([{ contenue: "", status: 0 }]);
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0);
    const modalRef = useRef();
    const [chapitre, setChapitre] = useState([]);

    const fetchCours = async () => {
        try {
            const response = await api.get(`/cours/prof`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setCours(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchChapitre = async () => {
        setChapitre(cours.flatMap(c => c.chapitres));
    };

    useEffect(() => {
        fetchCours();
        fetchChapitre();
    }, []);

    const EllipsisHandle = () => {
        setSelected(!selected);
    };

    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    };

    const handleIconClick = (index) => {
        setAnswers((prevAnswers) => prevAnswers.filter((_, i) => i !== index));
        if (correctAnswerIndex === index) setCorrectAnswerIndex(null);
    };

    const addAnswer = async () => {
        await setAnswers([...answers, { contenue: "", status: 0 }]);
    };

    const handleCoursChange = (selectedValue) => {
        setSelectedCours(selectedValue.target.value);
        const newSelectedChapitre = cours.filter(cour => cour.id == selectedValue.target.value);
        setChapitre(newSelectedChapitre.flatMap(c => c.chapitres));
    };

    const handleChapitreChange = (selectedValue) => {
        setSelectedChapitre(selectedValue.target.value);
    };

    const handleRadioChange = (index) => {
        setCorrectAnswerIndex(index);
        setAnswers(answers.map((answer, i) => ({
            ...answer,
            status: i === index ? 1 : 0
        })));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setSelected(false);
                setShowModal(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selected]);

    const EnregistrerClicked = async () => {
        try {
            const exercice = {
                question: question,
                id_chapitre: selectedChapitre,
                reponses: answers
            }
            const response = await api.post(`/exercices`, exercice, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setShowModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-full">
            {!isButton ? (
                <div className="block max-w-[18rem] bg-neutral-50 text-black shadow-secondary-1 py-3 mb-5">
                    <div className="relative px-6 py-3">
                        <div className="flex justify-between bg-white items-center">
                            <p className="text-2xl text-black/90 font-bold">{item.quest}</p>
                            <button onClick={EllipsisHandle} className="shadow-xl mr-3 px-3">
                                <FontAwesomeIcon icon={faEllipsisVertical} className="text-gray-700" />
                            </button>
                        </div>
                        {selected && (
                            <div ref={modalRef} className="absolute right-0 top-0 border border-gray-200 shadow-2xl bg-white">
                                <div className="border-b border-gray-200 p-3">
                                    <Link to="/exercice/modification-exercice">
                                        <FontAwesomeIcon icon={faEdit} className="text-gray-700" />
                                    </Link>
                                </div>
                                <div className="p-3 bg-white">
                                    <button>
                                        <FontAwesomeIcon icon={faTrash} className="text-gray-700" />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="mr-5 mb-5">
                    <div className="w-full">
                        <div className="w-full flex flex-col items-end">
                            <button
                                type="button"
                                className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                onClick={() => setShowModal(true)}
                            >
                                Créer question
                            </button>
                        </div>
                        <Modal
                            isOpen={showModal}
                            onRequestClose={() => setShowModal(false)}
                            className="fixed inset-0 z-50flex items-center justify-center bg-black bg-opacity-50"
                            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                            shouldCloseOnOverlayClick={true}
                        >
                            <div ref={modalRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[45vw]">
                                <div className="flex justify-between items-center p-5 border-b">
                                    <h5 className="text-xl font-medium">Nouvelle question</h5>
                                    <button
                                        type="button"
                                        className="text-black hover:text-gray-600"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="p-5">
                                    <OSSelect label="Cours" id="cours" items={cours} onChange={handleCoursChange} />
                                    <OSSelect label="Chapitre" id="Chapitre" items={chapitre} onChange={handleChapitreChange} />
                                    <br />
                                    <OSInput placeholder="Entrez la question" state={handleQuestionChange} />
                                    {answers.map((answer, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <input
                                                type="radio"
                                                id={`answer-${index}`}
                                                name="correct-answer"
                                                checked={correctAnswerIndex === index}
                                                onChange={() => handleRadioChange(index)}
                                                className="mr-2"
                                            />
                                            <LoginInput
                                                placeholder={`Réponse ${index + 1}`}
                                                state={(e) => {
                                                    const newAnswers = [...answers];
                                                    newAnswers[index].contenue = e.target.value;
                                                    setAnswers(newAnswers);
                                                }}
                                                onIconClick={() => handleIconClick(index)}
                                                icon={faTrash}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="flex justify-around p-5">
                                    <button
                                        type="button"
                                        className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                        onClick={addAnswer}
                                    >
                                        Ajouter réponse
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                        onClick={EnregistrerClicked}
                                    >
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            )}
        </div>
    );
};
