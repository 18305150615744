import React, { useEffect, useState } from "react";
import { CategorieCardAdmin } from "../../../Components/Cards/Admin/CategorieCard";
import api from "../../../store/Api/Intercepteur";
import { AucunElement } from "../../../Components/Cards/AucunElementCard";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";

export const Categorie = () => {
    const [categorie, setCategorie] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isrefresh, setisrefresh] = useState(false)

    const fetchCategorie = async () => {
        try {
            const response = await api.get(`/categories`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setCategorie(() => {
                return response.data
            });
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchCategorie()
    }, [isrefresh])

    const trigerRefresh = () => {
        setisrefresh(!isrefresh)
    }

    if (isLoading) return <LoadingScreen />

    return (
        <div className="relative ml-64 w-3/4 mt-20 min-h-screen">
            <CategorieCardAdmin isButton onRefresh={trigerRefresh} />
            {categorie.length !== 0 ? (
                <CategorieCardAdmin items={categorie} onRefresh={trigerRefresh} />
            ) : (
                <div className="w-1/2 ml-[15vw]">
                    <AucunElement item={"categorie"} />
                </div>
            )}
        </div>
    )
}