import React, { useState, useRef, useEffect } from "react";
import Modal from 'react-modal';
import { AucunElement } from "../AucunElementCard";
import { LoginInput } from "../../Input/LoginInput";
import { faChevronDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { OSInput } from "../../Input/OSInput";
import { OSSelect } from "../../Input/OSSelect";
import api from "../../../store/Api/Intercepteur";
import DeleteCard from "../../DeleteConfirmation/DeleteCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ExerciceCard = ({ course, onRefresh }) => {
    const [selectedCourseIndex, setSelectedCourseIndex] = useState(null);
    const [selectedChapterIndex, setSelectedChapterIndex] = useState(null);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([{ contenue: "", status: 0, id: "" }]);
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
    const [selectedCours, setSelectedCours] = useState(null);
    const [selectedChapitre, setSelectedChapitre] = useState(null);
    const [chapitre, setChapitre] = useState([]);
    const [selectedExerciseId, setSelectedExerciseId] = useState("")
    const [showSuppressionModal, setShowSuppressionModal] = useState(false)

    const modalRef = useRef(null);
    const modalSuppressionRef = useRef(null);

    const ModifierExercice = () => {
        setShowModal(true);
        setQuestion(selectedExercise.question);

        if (selectedExercise?.reponses) {
            setAnswers(selectedExercise.reponses.map(reponse => ({
                contenue: reponse.contenue,
                status: reponse.status,
                id: reponse.id
            })));
            const correctIndex = selectedExercise.reponses.findIndex(reponse => reponse.status === 1);
            setCorrectAnswerIndex(correctIndex);
        }
    };

    const addAnswer = async () => {
        await setAnswers([...answers, { contenue: "", status: 0, id: null }]);
    };


    const toggleShowChapters = (courseIndex) => {
        if (selectedCourseIndex === courseIndex) {
            setSelectedCourseIndex(null);
        } else {
            setSelectedCourseIndex(courseIndex);
            setSelectedChapterIndex(null);
            setSelectedExercise(null);
        }
    };

    const toggleShowExercises = (chapterIndex, id) => {
        if (selectedChapterIndex === chapterIndex) {
            setSelectedChapterIndex(null);
        } else {
            setSelectedChapterIndex(chapterIndex);
            setSelectedExercise(null);
        }
        setSelectedChapitre(id)
    };

    const handleExerciseClick = (exercise) => {
        setSelectedExercise(exercise);
    };

    const fetchChapitre = async () => {
        setChapitre(course.flatMap(c => c.chapitres));
    };

    useEffect(() => {
        fetchChapitre();
    }, []);

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value)
    };

    const handleRadioChange = (index) => {
        setCorrectAnswerIndex(index);
    };

    const handleIconClick = (index) => {

    };

    const EnregistrerClicked = async () => {
        try {
            const exercice = {
                question: question,
                id_chapitre: selectedChapitre,
                reponses: answers
            }
            const response = await api.put(`/exercices/${selectedExercise.id}`, exercice, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setShowModal(false)
            onRefresh()
        } catch (error) {
            console.log(error)
        }
    };

    const SuppressionClicked = () => {
        setShowSuppressionModal(true);
    }

    const confirmDelete = async () => {
        try {
            await api.delete(`/exercices/${selectedExercise.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
            onRefresh()
        } catch (error) {
            console.log(error)
        }
    }

    const cancelDelete = async () => {
        setShowSuppressionModal(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalSuppressionRef.current && !modalSuppressionRef.current.contains(event.target)) {
                setShowSuppressionModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex">
            <div className="w-96 fixed p-5 mt-[-35px] border shadow rounded h-[80vh] overflow-y-auto">
                {course?.map((cour, courseIndex) => (
                    <div key={courseIndex}>
                        <button
                            onClick={() => toggleShowChapters(courseIndex)}
                            className="mt-3 w-full text-black/60 max-h-16 border rounded-lg p-2 flex justify-between items-center"
                        >
                            <div className="flex items-center">
                                <div className="bg-secondary rounded-full w-10 h-10 text-center flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid">
                                        <rect x="3" y="3" width="7" height="7"></rect>
                                        <rect x="14" y="3" width="7" height="7"></rect>
                                        <rect x="14" y="14" width="7" height="7"></rect>
                                        <rect x="3" y="14" width="7" height="7"></rect>
                                    </svg>
                                </div>
                                <div className="ml-3 flex flex-col justify-center items-start">
                                    <p className="text-blue font-bold text-left">{cour.nom}</p>
                                    <span className="text-black/60 text-sm">{cour.chapitres.length} chapitres</span>
                                </div>
                            </div>
                            <FontAwesomeIcon icon={faChevronDown} className="text-black/70 w-3 h-3" />
                        </button>
                        {selectedCourseIndex === courseIndex && (
                            <div className="ml-4">
                                {cour.chapitres.map((chapitre, chapterIndex) => (
                                    <div key={chapterIndex}>
                                        <button
                                            onClick={() => toggleShowExercises(chapterIndex, chapitre.id)}
                                            className="mt-3 w-full text-black/60 border rounded-lg p-2 flex justify-between items-center"
                                        >
                                            <p>{chapitre.titre}</p>
                                            <FontAwesomeIcon icon={faChevronDown} className="text-black/70 w-3 h-3" />
                                        </button>

                                        {selectedChapterIndex === chapterIndex && (
                                            <div className="ml-4">
                                                {chapitre.exercices.map((exo, i) => (
                                                    <button
                                                        key={i}
                                                        onClick={() => handleExerciseClick(exo)}
                                                        className="text-black/50 mt-2 px-4 w-full flex justify-between items-center border rounded-lg p-2"
                                                    >
                                                        <p>Exercice {i + 1}</p>
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="w-[45vw] ml-[400px] p-5 border shadow rounded">
                {selectedExercise ? (
                    <div>
                        <h2 className="text-blue font-bold">{selectedExercise.question}</h2>
                        {selectedExercise?.reponses && (
                            <ul>
                                {selectedExercise?.reponses.map((reponse, i) => (
                                    <li key={i} className="text-black/60 my-3">
                                        {reponse.contenue}
                                    </li>
                                ))}
                            </ul>
                        )}
                        <div className="border-t pt-5 flex justify-around w-full text-white font-semibold">
                            <button className="px-4 py-1 bg-secondary rounded" onClick={ModifierExercice}>Modifier</button>
                            <button className="px-4 py-1 bg-red-500 rounded" onClick={SuppressionClicked}>Supprimer</button>
                        </div>
                    </div>
                ) : (
                    <AucunElement item={"exercice"} />
                )}
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                shouldCloseOnOverlayClick={true}
            >
                <div ref={modalRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[20vw]">
                    <div className="flex justify-between items-center p-5 border-b">
                        <h5 className="text-xl font-medium">Modifier l'exercice</h5>
                        <button
                            type="button"
                            className="text-black hover:text-gray-600"
                            onClick={() => setShowModal(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="p-5">
                        <OSInput placeholder="Entrez la question" defaultValue={question} state={handleQuestionChange} />
                        {answers.map((answer, index) => (
                            <div key={index} className="flex items-center mb-2 justify-center">
                                <input
                                    type="radio"
                                    id={`answer-${index}`}
                                    name="correct-answer"
                                    checked={correctAnswerIndex === index}
                                    onChange={() => handleRadioChange(index)}
                                    className="mr-2"
                                />
                                <LoginInput
                                    placeholder={`Réponse ${index + 1}`}
                                    state={(e) => {
                                        const newAnswers = [...answers];
                                        newAnswers[index].contenue = e.target.value;
                                        newAnswers[index].id = answer.id
                                        setAnswers(newAnswers);
                                    }}
                                    defaultValue={answer.contenue}
                                    onIconClick={() => handleIconClick(index)}
                                    icon={faTrash}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-around p-5">
                        <button
                            type="button"
                            className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                            onClick={addAnswer}
                        >
                            Ajouter réponse
                        </button>
                        <button
                            type="button"
                            className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.3)] transition-all duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_0_rgba(0,0,0,0.3)] focus:bg-primary-600 focus:shadow-[0_8px_9px_0_rgba(0,0,0,0.3)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_4px_9px_-4px_rgba(0,0,0,0.3)]"
                            onClick={EnregistrerClicked}
                        >
                            Enregistrer
                        </button>
                    </div>
                </div>
            </Modal>

            {/* SUPPRESION*/}
            <Modal
                isOpen={showSuppressionModal}
                onRequestClose={() => setShowModal(false)}
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                shouldCloseOnOverlayClick={true}
            >
                <div ref={modalSuppressionRef} className="bg-white rounded-lg shadow-lg w-1/3 max-h-[80vh] overflow-x-auto mt-20 ml-[20vw]">
                    <DeleteCard onCancel={cancelDelete} onConfirm={confirmDelete} />
                </div>
            </Modal>
        </div>
    );
};
