import React, { useState } from "react";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddPhoto = ({ onDrop, currentImage }) => {
	const [image, setImage] = useState(currentImage ? currentImage : null)

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setImage(URL.createObjectURL(file));
			onDrop(file);
		}
	};

	return (
		<div className="flex flex-col justify-center items-center">
			<div className="w-40 h-40 border border-dashed border-gray-400 rounded-full">
				<input
					type="file"
					accept="image/*"
					onChange={handleFileChange}
					className="hidden"
					id="file-input"
				/>
				<label htmlFor="file-input" className="w-full h-full flex p-2 flex-col justify-center items-center cursor-pointer bg-grey rounded-full">
					{!image ? (
						<>
							<FontAwesomeIcon icon={faCamera} className="text-primary w-10 h-10" />
							<p className="text-xs text-center text-primary">Ajouter photo</p>
						</>
					) : (
						<img alt="Image sélectionnée" src={image} className="w-full h-full object-cover rounded-full" />
					)}
				</label>
			</div>
			<p className="text-xs mt-3">Type de fichier : *.jpeg,*.jpg,*.png</p>
		</div>
	);
};

export default AddPhoto;
