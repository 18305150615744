import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const createSelectors = (_store) => {
    let store = _store;
    store.use = {};
    for (let k of Object.keys(store.getState())) {
        store.use[k] = () => store((s) => s[k]);
    }
    return store;
};

export const UseLanguage = createSelectors(
    create(
        persist(
            (set, get) => ({
                language: "fr",

                changeLanguage(selectedLanguage) {
                    set({ language: selectedLanguage });
                    return { success: true, currentLanguage: selectedLanguage };
                },
            }),
            {
                name: "language-storage",
                getStorage: () => createJSONStorage(() => localStorage),
            }
        )
    )
);
