import { faBusinessTime, faGraduationCap, faLayerGroup } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"

export const Profile = () => {
    const [items, setItems] = useState(0)

    const renderContent = (items) => {
        switch (items) {
            case 0:
                return (
                    <div>
                        <div className="my-5">
                            <p className="text-blue-950 font-bold text-xl">Éducation</p>
                            <ul className="text-gray-500">
                                <li><span className="text-4xl font-bold">.</span> Diplôme d'associé en physiothérapie de l'Université de la Colombie-Britannique</li>
                                <li><span className="text-4xl font-bold">.</span>  Licence ès arts en psychologie de l'Université Duke</li>
                                <li><span className="text-4xl font-bold">.</span>  Master en santé publique de l'Université Cornell</li>
                            </ul>
                        </div>
                        <div className="my-5">
                            <p className="text-blue-950 font-bold text-xl">À propos</p>
                            <p className="text-black/80">Je suis un coach certifié et auteur. Au fil des ans, j'ai travaillé avec et coaché 100 000 étudiants du monde entier.
                                Mon expertise comprend le développement personnel basé sur la science, des conseils en matière de santé et de fitness dans les domaines suivants :

                                Amélioration de soi
                                Coaching de vie
                                Gestion du stress
                                Croissance musculaire et perte de graisse
                                Mode de vie sain et planification des repas
                                Entraînements en salle de sport et musculation

                                Le développement personnel ne doit pas être difficile !
                                Ce dont vous avez besoin, ce sont les bonnes stratégies et quelques astuces simples -
                                mais cruciales - sur la manière de commencer et de rester motivé.
                                C'est ce que j'enseigne dans mes cours, sur mon blog et dans mes livres.
                            </p>
                        </div>
                        <div className="my-5">
                            <p className="text-blue-950 font-bold text-xl">Compétences</p>
                            <p className="text-gray-500">Santé et fitness</p>
                            <p className="text-gray-500">Style de vie</p>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div>
                        {/* Contenu pour items 1 */}
                    </div>
                )
            case 2:
                return (
                    <div>
                        {/* Contenu pour items 2 */}
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <div className="relative min-h-screen flex flex-col items-center justify-start">
            <div className="absolute w-full h-72 bg-gray-200">
                {/* <p className="text-center texte-white">COUVERTURE</p> */}
                <img className="w-full object-cover h-full" src="https://images.unsplash.com/photo-1465146344425-f00d5f5c8f07?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGhvdG8lMjBkZSUyMGNvdXZlcnR1cmV8ZW58MHx8MHx8fDA%3D" alt="COUVERTURE" />
            </div>
            <div className="absolute top-32 w-3/4 max-sm:w-5/6 bg-white shadowflex flex-col rounded-xl" >
                <div className="z-0 mt-3">
                    <div className="flex justify-between p-5 border-b border-gray-300 max-sm:flex-col max-sm:items-center max-sm:w-full">
                        <div className="flex justify-start max-sm:flex-col max-sm:items-center max-sm:relative max-sm:w-4/5">
                            <img src="http://lms.rocket-soft.org/store/870/avatar/617a4f7c09d61.png" alt="profile" className="rounded-full w-52 h-52 max-lg:w-24 max-lg:h-24 max-sm:absolute max-sm:top-[-80px]" />
                            <div className="flex flex-col justify-center ml-12 max-sm:w-2/4 max-sm:ml-0">
                                <p className="text-3xl text-blue-950 font-bold max-lg:text-xl max-lg:font-extrabold max-lg:mt-7 max-sm:text-center">Jessica Wray</p>
                                <div className="flex justify-around items-center mt-11 max-lg:mt-3 max-sm:w-full">
                                    <div>
                                        <p className="text-blue-950 font-bold">100</p>
                                        <p className="text-black/70">Followers</p>
                                    </div>
                                    <div className="border-l pl-2 border-gray-300 max-lg:ml-3">
                                        <p className="text-blue-950 font-bold">35</p>
                                        <p className="text-black/70">Following</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="bg-primary text-white rounded-md px-3 h-8 py-1 max-sm:w-28 max-sm:py-2 mt-3 max-sm:h-auto">Suivre</button>
                    </div>
                    <div className="flex justify-around w-full py-5 shadow-lg rounded-lg mb-5">
                        <div>
                            <div className="rounded-xl border-2 border-[#ef9d69] px-5 py-4 shadow-[#ef936a4d]">
                                <FontAwesomeIcon icon={faGraduationCap} className="text-[#ef9d69] w-8 h-8" />
                            </div>
                            <p className="text-blue-950 font-extrabold text-lg text-center mt-1">52</p>
                            <p className="text-gray-800/80 text-center">Elèves</p>
                        </div>
                        <div>
                            <div className="rounded-xl border-2 border-[#00a1d9] px-5 py-4">
                                <FontAwesomeIcon icon={faBusinessTime} className="text-[#00a1d9] w-8 h-8" />
                            </div>
                            <p className="text-blue-950 font-extrabold text-lg text-center my-1">5</p>
                            <p className="text-gray-800/80 text-center">Cours</p>
                        </div>
                        <div>
                            <div className="rounded-xl border-2 border-[#4fb949] px-5 py-4">
                                <FontAwesomeIcon icon={faLayerGroup} className="text-[#4fb949] w-8 h-8" />
                            </div>
                            <p className="text-blue-950 font-extrabold text-lg text-center my-1">5</p>
                            <p className="text-gray-800/80 text-center">Cours</p>
                        </div>
                    </div>
                    <div className="w-full mt-5 border border-gray-200 rounded-xl p-3">
                        <div className="flex justify-around mt-3 text-blue-950 font-extrabold text-lg border-b borser-gray-200">
                            <div>
                                <button onClick={() => setItems(0)}>A propos</button>
                                <div className={`${items === 0 ? 'rounded-md h-1 w-full bg-secondary mt-2' : ""}`}></div>
                            </div>
                            <div>
                                <button onClick={() => setItems(1)}>Cours</button>
                                <div className={`${items === 1 ? 'rounded-md h-1 w-full bg-secondary mt-2' : ""}`}></div>
                            </div>
                            <div>
                                <button onClick={() => setItems(2)}>Contact</button>
                                <div className={`${items === 2 ? 'rounded-md h-1 w-full bg-secondary mt-2' : ""}`}></div>
                            </div>
                        </div>
                        <div>{renderContent(items)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
