import React from 'react';
import translate from '../../store/Translation/Langue';
import { UseLanguage } from '../../store/User/language';

function LoadingScreen() {
    const language = UseLanguage.use.language()
    return (
        <div className="loader-container flex flex-col w-full justify-center items-center h-screen">
            <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-primary"
                role="status">
            </div>
            <span
                className="text-black/60 flex items-center"
            >{translate(language, "loading")}...</span>
            {/* <div class="w-full">
                <div class="h-1.5 w-full bg-pink-100 overflow-hidden">
                    <div class="animate-progress w-full h-full bg-primary origin-left-right"></div>
                </div>
            </div> */}
            {/* <div className="relative">
                <div
                    className={`${language === "fr" ? "w-28 h-28" : ''} h-20 w-20 rounded-full border-4 border-transparent border-t-primary border-l-primary animate-spin`}
                ></div>
                <span className="absolute inset-0 flex items-center justify-center text-gray-400">{translate(language, "loading")}</span>
            </div> */}
        </div>

    );

}
export default LoadingScreen;

