import classNames from 'classnames'
import React from 'react'

export const OSButton = ({ value, liste }) => {
  return (
    <div className={classNames(liste ? "" : 'float-right')}>
      <button type="submit" className="text-light bg-primary text-white font-bold rounded w-40 text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">{value}</button>
    </div>
  )
}
