import React from "react";

export const Certificat = () => {
    return (
        <div className="min-h-screen">
            <h1>Vérification Authentique des Certificats</h1>
            <p> Le certificats attestent que vous avez acquis certaines connaissances ou compétences après avoir
                suivi un cours ou une formation. Notre institution a mis en place un système de vérification de
                certificat en ligne pour garantir l'authenticité et l'intégrité des certificats que nous délivrons.
                Cette démarche renforce la confiance dans nos certifications, facilite la vérification pour les employeurs,
                et lutte contre les fraudes. Nous utilisons des technologies de pointe pour garantir la sécurité et
                l'intégrité des données, et nous sommes fiers de garantir à nos partenaires une transparence totale
                dans l'émission de nos certificats
            </p>
        </div>
    )
}