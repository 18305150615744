import { faBars, faBell, faChevronDown, faChevronUp, faLayerGroup, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppStore } from "../../store/User/user";
import translate from '../../store/Translation/Langue';
import { UseLanguage } from "../../store/User/language";
import { translations } from "../../store/Translation/Langue";

const pathCorrect = "border-b-4 border-[#06a440] text-[#06a440] font-normal px-5 mx-1 h-14 flex flex-col justify-center"
const pathIncorrect = "text-gray-700 p-5 mx-1 font-normal"


export const Navbar = () => {
    const { pathname } = useLocation()
    const [select, setSelect] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const language = UseLanguage.use.language()
    const logout = useAppStore.use.logout()
    const changeLanguage = UseLanguage.use.changeLanguage()
    const [selectionLanguage, setSelectionLanguage] = useState(false);
    const dropdownRef = useRef(null);
    const menuRef = useRef(null)
    const user = useAppStore.use.user()
    const userInfo = user.info
    const modalRef = useRef()

    const handleLanguageChange = (lang) => {
        changeLanguage(lang)
        setSelectionLanguage(false)
    };

    const SelectOnClick = () => {
        setSelect(!select)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSelectionLanguage(false)
        }
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false)
        }
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setSelect(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [selectionLanguage, showMenu])


    return (
        <nav className="h-14 fixed flex flex-col items-center justify-center border-b border-gray-300 shadow-md bg-white w-full z-20">
            <div className="w-5/6 max-xl:w-full flex justify-around max-md:justify-between max-md:pl-12">
                <div className="lg:hidden flex flex-col justify-center items-around">
                    <button onClick={(e) => setShowMenu(!showMenu)} className="border border-gray-300 py-1 px-3">
                        <FontAwesomeIcon icon={faBars} className="text-gray-400" />
                    </button>
                </div>
                <div className="flex flex-col justify-center lg:hidden">
                    <img src="/logo.jpeg" alt="LOGO" className="w-9 h-9" />
                    {/* <p className="text-blue-950">Nom plateforme</p> */}
                </div>
                <div className="flex items-center justify-around max-lg:hidden">
                    <div className="flex items-center justify-around px-6 max-lg:px-0">
                        <img src="/logo.jpeg" alt="LOGO" className="w-9 h-9" />
                        {/* <p className="text-blue-950">Nom plateforme</p> */}
                    </div>
                    <ul className="flex items-center justify-end ml-36 max-lg:justify-center max-lg:w-4/5">
                        <li className={classNames(pathname === "/" ? pathCorrect : pathIncorrect)}>
                            <Link to="/">
                                <p>{translate(language, "home")}</p>
                            </Link>
                        </li>
                        <li className={classNames(pathname === "/cours" ? pathCorrect : pathIncorrect)}>
                            <Link to="/cours">
                                <p>{translate(language, "courses")}</p>
                            </Link>
                        </li>
                        <li className={classNames(pathname === "/recherche" ? "text-primary" : "text-gray-700")}>
                            <Link to="/recherche" className="max-lg:ml-2">
                                <FontAwesomeIcon icon={faSearch} className="px-2" />
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="relative flex items-center justify-around max-md:justidy-end max-lg:w-28">
                    <Link to="/AddPoint" className="max-lg:hidden mr-7">
                        <p className="hover:bg-primary text-center font-bold text-white w-32 py-1 rounded-lg shadow shadow-cyan-500/50 bg-secondary">{translate(language, "addPoint")}</p>
                    </Link>
                    <div ref={dropdownRef} className="relative mr-7">
                        <button
                            onClick={() => setSelectionLanguage(!selectionLanguage)}
                            className="text-black/90"
                        >
                            <img src={translate(language, "flag")} alt="language" className="w-4 h-3 object-cover " />
                        </button>
                        {selectionLanguage && (
                            <div className="absolute w-40 bg-white shadow rounded-md border-gray-100 top-9">
                                {Object.keys(translations).map((langCode) => (
                                    <div>
                                        <button
                                            key={langCode} onClick={() => handleLanguageChange(langCode)}
                                            className="flex items-center gap-x-2 text-gray-600 py-1 pl-3"
                                        >
                                            <img src={translate(langCode, "flag")} alt="language" className="w-4 h-3 object-cover" />
                                            {translate(language, langCode)}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <Link to="/notification" className="mr-7">
                        <FontAwesomeIcon icon={faBell} color="gray" className="md:mx-0" />
                    </Link>
                    <Link to="/profile" className="md:hidden">
                        <img src="http://lms.rocket-soft.org/store/929/Solar%20Energy%20Design%20Course%20From%20Zero%20To%20Hero.jpg" alt="user" className="w-7 h-7 rounded-full" />
                    </Link>
                    <div className="flex justify-around max-md:hidden">
                        <img src="http://lms.rocket-soft.org/store/929/Solar%20Energy%20Design%20Course%20From%20Zero%20To%20Hero.jpg" alt="user" className="w-7 h-7 rounded-full" />
                        <button onClick={SelectOnClick} className="flex items-center">
                            <p className="text-slate-950 font-bold mx-2">{userInfo.name}</p>
                            <FontAwesomeIcon icon={faChevronDown} className={`w-3 h-3 text-black/80 ${select ? 'transition-all duration-500 linear rotate-180' : "transition-all duration-500 linear"}`} />
                        </button>
                    </div>
                    {select && (
                        <div ref={modalRef} className="absolute bg-white border border-gray-100 shadow w-36 right-0 top-14 rounded">
                            <div className="w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200">
                                <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                    <div className="absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300"></div>
                                </div>
                                <div className="group-hover:bg-white/10 w-full group-active:scale-95 self-stretch pl-2 rounded flex items-center space-x-2 transition-all duration-200 text-gray-600 text-sm h-11" href="#">
                                    <svg className="h-5 w-5 group-hover:fill-thirdly dark:fill-gray-600  transition-colors duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                                        <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
                                    </svg>
                                    <Link to="/profile" className="font-QuicksandMedium">{translate(language, "profile")}</Link>
                                </div>
                            </div>
                            <div className="w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200">
                                <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                    <div className="absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300"></div>
                                </div>
                                <div className="group-hover:bg-white/10 w-full group-active:scale-95 self-stretch pl-2 rounded flex items-center space-x-2 transition-all duration-200 text-gray-600 text-sm h-11" href="#">
                                    <svg className="h-5 w-5 group-hover:fill-thirdly dark:fill-gray-600  transition-colors duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M19 21H5C3.89543 21 3 20.1046 3 19V15H5V19H19V5H5V9H3V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM11 16V13H3V11H11V8L16 12L11 16Z"></path>
                                    </svg>
                                    <Link to="/authentification" className="font-QuicksandMedium" onClick={() => logout()}>Deconnexion</Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div
                ref={menuRef}
                className={`absolute  flex items-start w-1/2 justify-between top-14 bg-white left-0 transition-all duration-200 linear h-screen`}
                style={{ left: showMenu ? '0px' : "-50vw" }}
            >
                <ul className={`px-5 relative h-full`} >
                    <li className="py-2">
                        <Link to="/">
                            <p>Accueil</p>
                        </Link>
                    </li>
                    <li className="py-2">
                        <Link to="/cours">
                            <p>Cours</p>
                        </Link>
                    </li>
                </ul>
                <li className="absolute bottom-14 py-2 w-full flex flex-col bg-gray-200">
                    <Link to="/authentification" className="text-center w-full" onClick={() => logout()}>Deconnexion</Link>
                </li>
                <div className={``}>
                    <button onClick={(e) => setShowMenu(false)} className="px-4 py-3">
                        <FontAwesomeIcon icon={faXmark} className="text-black/80 w-5 h-5" />
                    </button>
                </div>
            </div>
        </nav >
    );
};
