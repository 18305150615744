import React, { useEffect, useState } from "react";
import { OSInput } from "../../Components/Input/OSInput";
import { OSButton } from "../../Components/Button/OSButton";
import { OSSelect } from "../../Components/Input/OSSelect";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TextAreaInput } from "../../Components/Input/TextareaInput";
import api from "../../store/Api/Intercepteur";
import AddPhoto from "../../Components/AddPhoto/AddPhoto";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const NouveauCours = () => {
    const [nomCours, setNomCours] = useState("");
    const [Categorie, setCategorie] = useState([]);
    const [description, setDescription] = useState('');
    const [selectedCategorie, setSelectedCategorie] = useState("");
    const [editorValue, setEditorValue] = useState("");
    const [cout, setCout] = useState('');
    const [photo, setPhoto] = useState(null);
    const navigation = useNavigate();

    const fetchCategorie = async () => {
        try {
            const response = await api.get(`/categories`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setCategorie(() => {
                return response.data;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const initializeEditor = () => {
        DecoupledEditor
            .create(document.querySelector('#editor'))
            .then(editor => {
                const toolbarContainer = document.querySelector('#toolbar-container');
                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                editor.model.document.on('change:data', () => {
                    const data = editor.getData();
                    setEditorValue(data);
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        fetchCategorie();
        initializeEditor();
    }, []);

    const AjouterClicked = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("nom", nomCours);
        formData.append("id_categorie", selectedCategorie);
        formData.append("details", editorValue);
        formData.append("description", description);
        formData.append("cout", cout);
        formData.append("image", photo);
        try {
            await api.post(`/cours`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                }
            });
            toast.success("Nouveau cours crée avec succès!", {
                onClose: () => {
                    navigation(`/cours`);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };



    const AnnulerClicked = () => {
        setNomCours('');
        setDescription('');
        setEditorValue('');
        setPhoto(null);
        navigation('/cours');
    };

    return (
        <div className="ml-72 mt-16 flex flex-col items-center justify-center max-lg:w-[1/2]">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Ajout nouveau cours</p>
            </div>
            <form onSubmit={AjouterClicked} className="rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div className="flex justify-around">
                    <div className="border rounded-md h-72 w-72 flex flex-col justify-center">
                        <AddPhoto onDrop={(file) => setPhoto(file)} />
                    </div>
                    <div className="w-2/3">
                        <OSInput placeholder="Titre" id="nom" state={((e) => setNomCours(e.target.value))} />
                        <OSInput placeholder="cout" id="cout" state={(e) => setCout(e.target.value)} />
                        <p className="text-gray-500">Categorie :</p>
                        <OSSelect id="Categorie" items={Categorie} onChange={((e) => setSelectedCategorie(e.target.value))} />
                        <TextAreaInput label="Description du cours" id="description" onChange={((e) => setDescription(e.target.value))} />
                    </div>
                </div>
                <div className="w-full">
                    <h2 className="text-gray-700">Ce qu'on va apprendre : </h2>
                    <div id="toolbar-container"></div>
                    <div id="editor" className="min-h-40 border max-h-96 h-auto"></div>
                </div>
                <div className="flex justify-between items-center mt-16 px-20">
                    <button onClick={AnnulerClicked} className="text-light bg-red-600 font-bold text-white rounded w-40 text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Ajouter" />
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};
