import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./Layout";

import { Authentification } from "./Pages/Authentification/Authentification";
import { Inscription } from "./Pages/Authentification/Inscription";

import { Home } from "./Pages/Accueil";

import { Cours } from "./Pages/Cours/Cours";
import { NouveauCours } from "./Pages/Cours/NouveauCours";
import { ModificationCours } from "./Pages/Cours/ModificationCours";
import { ViewCours } from "./Pages/Cours/ViewCours";

import { NouveauChapitre } from "./Pages/chapitre/NouveauChapitre";

import { Examen } from "./Pages/Examen/Examen";
import { PasserExamen } from "./Pages/Examen/PasserExamen";
import { CreerExamen } from "./Pages/Examen/Professeur/CreerExamen";

import { Recherche } from "./Pages/Recherche";

import { Profile } from "./Pages/Profile/Profile";
import { DetailCours } from "./Pages/Cours/Details";
import { Contact } from "./Pages/Visiteur/Contact";
import { Categorie } from "./Pages/BackOffice/Categorie/Categorie";
import { Exercice } from "./Pages/Examen/Professeur/Exercice";
import { ModificationExercice } from "./Pages/Examen/Professeur/ModificationExercice";
import { ViewCoursSuivi } from "./Pages/Cours/ViewCoursSuivi/ViewCoursSuivi";
import ViewChapitre from "./Pages/chapitre/ViewChapitre";
import { ModificationChapitre } from "./Pages/chapitre/ModificationChapitre";
import DeleteCard from "./Components/DeleteConfirmation/DeleteCard";
import { NotificationCard } from "./Components/Notification/NotificationCard";
import { Utilisateur } from "./Pages/BackOffice/Utilisateur/Utilisateur";
import { AddPoint } from "./Pages/AddPoint/AddPoint";
import { Paiment } from "./Pages/BackOffice/Paiment.jsx/Paiment";
import GetCertificate from "./Pages/Certificat/GetCertificate";
import { ResetPassword } from "./Pages/Authentification/ResetPassword";
import { useAppStore } from "./store/User/user";
import { useEffect } from "react";
import VideoMeet from "./Pages/Meet/Meet";
import { Certificat } from "./Pages/Certificat/Certificat";

function App() {
    const check = useAppStore.use.check()
    const type = useAppStore.use.type()

    useEffect(() => {
        if (type === "") {
            return
        }
        check()
    }, [type])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/authentification" exact element={<Authentification />} />
                <Route path="/inscription" exact element={<Inscription />} />
                <Route path="/resetPassword" exact element={<ResetPassword />} />
                <Route path="/" exact element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/recherche" element={<Recherche />} />
                    <Route path="/profile" exact element={<Profile />} />
                    <Route path="/contact" exact element={<Contact />} />
                    <Route path="/AddPoint" exact element={<AddPoint />} />
                    <Route path="/paiment" exact element={<Paiment />} />
                    <Route path="/meet" exact element={<VideoMeet />} />
                    <Route path="/certificat" exact element={<Certificat />} />
                </Route>
                <Route path="/cours" exact element={<Layout />}>
                    <Route index element={<Cours />} />
                    <Route path="/cours/nouveau-cours" exact element={<NouveauCours />} />
                    <Route
                        path="/cours/modification-cours"
                        exact
                        element={<ModificationCours />}
                    />
                    <Route path="/cours/view-cours" exact element={<ViewCours />} />
                    <Route path="/cours/detail-cours" exact element={<DetailCours />} />
                    <Route path="/cours/view-coursSuivi" exact element={<ViewCoursSuivi />} />
                </Route>
                <Route path="/exercice" exact element={<Layout />}>
                    <Route index element={<Exercice />} />
                    <Route path="/exercice/modification-exercice" element={<ModificationExercice />} />
                </Route>
                <Route path="/Chapitre" exact element={<Layout />}>
                    <Route index element={<Examen />} />
                    <Route path="/Chapitre/Nouveau-Chapitre" exact element={<NouveauChapitre />} />
                    <Route
                        path="/Chapitre/view-chapitre"
                        element={<ViewChapitre />}
                        exact
                    />
                    <Route path="/Chapitre/modification-chapitre" exact element={<ModificationChapitre />} />
                </Route>
                <Route path="/examen" exact element={<Layout />}>
                    <Route index element={<Examen />} />
                    <Route path="/examen/creer-examen" exact element={<CreerExamen />} />
                    <Route
                        path="/examen/passer-examen"
                        element={<PasserExamen />}
                        exact
                    />
                </Route>
                <Route path="/certificat/:id" element={<GetCertificate />} />
                <Route path="*" element={<div>Page not found</div>} />
                {/* back office */}
                <Route path="/categorie" exact element={<Layout />}>
                    <Route index element={<Categorie />} />
                    <Route path="/categorie/nouveau-categorie" exact element={<NouveauCours />} />
                    <Route
                        path="/categorie/modification-categorie"
                        exact
                        element={<ModificationCours />}
                    />
                </Route>
                <Route path="/utilisateur" exact element={<Layout />}>
                    <Route index element={<Utilisateur />} />
                </Route>
                <Route path="/delete" exact element={<DeleteCard />} />
                <Route path="/confirmation" exact element={<NotificationCard />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
