import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../../store/Api/Intercepteur";
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const ViewCoursSuivi = () => {
    const location = useLocation()
    const selectedItem = location.state.selectedItem
    const modalRef = useRef()

    // const getCours = async()=>{
    //     try {
    //         const response = await api.get('/cours')
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }


    const chapitre = [
        {
            title: "chapitre 1",
            exercice: [
                {
                    quest: "Question 1",
                    res: ["reponse 1", "reponse 2", "reponse 3"]
                },
                {
                    quest: "Question 2",
                    res: ["reponse 1", "reponse 2"]
                },
                {
                    quest: "Question 3",
                    res: ["reponse 1", "reponse 2", "reponse 3", "reponse 4"]
                }
            ]
        },
        {
            title: "chapitre 2",
            exercice: []
        },
        {
            title: "chapitre 3",
            exercice: [
                {
                    quest: "Question 1",
                    res: ["reponse 1", "reponse 2", "reponse 3"]
                },
                {
                    quest: "Question 2",
                    res: ["reponse 1", "reponse 2"]
                },
                {
                    quest: "Question 3",
                    res: ["reponse 1", "reponse 2", "reponse 3", "reponse 4"]
                }
            ]
        }
    ]

    const AffichageChapitre = ({ chapitre, exercice }) => {
        const [showContent, setShowContent] = useState(false)
        const [showModal, setShowModal] = useState(false)
        const [exos, setExos] = useState([])

        const viewModalQuestion = (exo) => {
            setShowModal(true)
            setExos(exo)
        }

        const closeQuestion = () => {
            setShowModal(false)
        }

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    setShowModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }, [])

        return (
            <div className="w-full">
                <button onClick={() => setShowContent(!showContent)} className="text-black/60 w-full border rounded-lg p-2 flex justify-between items-center">
                    <div className="flex items-center">
                        <div className="bg-secondary rounded-full w-10 h-10 text-center flex flex-col justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid">
                                <rect x="3" y="3" width="7" height="7"></rect>
                                <rect x="14" y="3" width="7" height="7"></rect>
                                <rect x="14" y="14" width="7" height="7"></rect>
                                <rect x="3" y="14" width="7" height="7"></rect>
                            </svg>
                        </div>
                        <div className="ml-3 flex flex-col justify-center">
                            <p className="text-blue font-bold">{chapitre}</p>
                            <span className="text-black/60 text-sm">{exercice.length} exercices</span>
                        </div>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faChevronDown} className={`w-4 h-4 ${showContent ? 'transition-all duration-500 linear rotate-180' : "transition-all duration-500 linear"}`} />
                    </div>
                </button>
                <div className={`${showContent ? `h-40` : "h-0"} overflow-y-auto transition-all ease-linear`}>
                    {
                        exercice.map((exo, i) => (
                            <button onClick={() => viewModalQuestion(exo)} className="text-black/50 mt-2 px-4 w-full flex justify-between items-center" key={i}>
                                <div className="flex justify-between items-center">
                                    <div className="w-10 h-10 flex justify-center items-center bg-gray-300 rounded-full">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-feather text-gray">
                                            <path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path>
                                            <line x1="16" y1="8" x2="2" y2="22"></line>
                                            <line x1="17.5" y1="15" x2="9" y2="15"></line>
                                        </svg>
                                    </div>
                                    <div className="ml-2 text-blue">
                                        <p>{exo.quest}</p>
                                    </div>
                                </div>
                            </button>
                        ))}
                </div>
                <Modal
                    isOpen={showModal}
                    onRequestClose={() => setShowModal(false)}
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                    shouldCloseOnOverlayClick={true}
                >
                    <div
                        ref={modalRef}
                        className="bg-white rounded-lg shadow-lg w-1/3"
                    >
                        <div className="flex justify-between items-center p-5 border-b">
                            <h5 className="text-xl font-medium">{exos.quest}</h5>
                            <button
                                type="button"
                                className="text-black hover:text-gray-600"
                                onClick={() => setShowModal(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex flex-col items-center">
                            {exos.res?.map((response, index) => (
                                <p key={index} className="my-2">{response}</p>
                            ))}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    return (
        <div className="w-full py-2 min-h-96">
            <div>
                <div className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g id="Mask_Group_17" clipPath="url(#clipPath)" data-name="Mask Group 17" transform="translate(-25 -410)">
                            <g id="online-className" transform="translate(25 410)">
                                <path id="Path_153" d="M22.078 12.319a2.112 2.112 0 0 0 1.922-2.1V3.656a2.112 2.112 0 0 0-2.109-2.109h-6.985A2.112 2.112 0 0 0 12.8 3.656v2.766H4.031a2.112 2.112 0 0 0-2.109 2.109v8.438a2.1 2.1 0 0 0 .121.7h-.777A1.267 1.267 0 0 0 0 18.938a3.52 3.52 0 0 0 3.516 3.516h16.968A3.52 3.52 0 0 0 24 18.938a1.267 1.267 0 0 0-1.266-1.266h-.777a2.1 2.1 0 0 0 .121-.7zM14.2 3.656a.7.7 0 0 1 .7-.7h6.984a.7.7 0 0 1 .7.7v6.562a.7.7 0 0 1-.7.7h-6.509a.7.7 0 0 0-.373.107l-1.418.886.589-1.963a.7.7 0 0 0 .03-.2zm6.281 17.391H3.516a2.112 2.112 0 0 1-2.1-1.969h21.173a2.112 2.112 0 0 1-2.105 1.969zM6.7 12.375a.8.8 0 1 1 .8.8.8.8 0 0 1-.8-.8zm-.375 3c0-.424.548-.8 1.172-.8a1.435 1.435 0 0 1 .885.287.692.692 0 0 1 .287.51v2.3H6.328zm3.75 2.3v-2.3a2.074 2.074 0 0 0-.815-1.608l-.036-.027a2.2 2.2 0 1 0-3.455 0 2.073 2.073 0 0 0-.851 1.634v2.3h-.887a.7.7 0 0 1-.7-.7V8.531a.7.7 0 0 1 .7-.7H12.8v1.816l-.559 1.864a1.4 1.4 0 0 0 2.092 1.6l1.247-.779h5.1v4.641a.7.7 0 0 1-.7.7z" className="cls-3" data-name="Path 153"></path>
                                <path id="Path_154" d="M19.125 7.922h-1.5a.7.7 0 0 0 0 1.406h1.5a.7.7 0 0 0 0-1.406z" className="cls-3" data-name="Path 154"></path>
                                <path id="Path_155" d="M16.5 5.953h3.75a.7.7 0 0 0 0-1.406H16.5a.7.7 0 0 0 0 1.406z" className="cls-3" data-name="Path 155"></path>
                            </g>
                        </g>
                    </svg>
                    <p className="ml-1 text-black/60">Exercice</p>
                </div>
                <div className="w-20 h-1.5 mt-1 rounded-lg bg-secondary"></div>
            </div>
            {chapitre?.map((c, index) => (
                <div className="mt-2" key={index}>
                    <AffichageChapitre chapitre={c.title} exercice={c.exercice} />
                </div>
            ))}
        </div>
    )
}