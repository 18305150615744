import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBriefcase, faCircle, faCode, faSearch, faStar } from "@fortawesome/free-solid-svg-icons";
import Lottie from 'react-lottie';
import * as animationData from '../../../Lottie/Animation.json';
import { Link, useNavigate } from "react-router-dom";
import { useAppStore } from "../../../store/User/user";
import { UseLanguage } from "../../../store/User/language";
import translate from "../../../store/Translation/Langue";
import Typewriter from "typewriter-effect";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import { faPersonRunning } from "@fortawesome/free-solid-svg-icons/faPersonRunning";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons/faUserGraduate";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons/faGraduationCap";
import { SearchBar } from "../../Searchbar/SearchBar";
import { faChalkboardUser } from "@fortawesome/free-solid-svg-icons/faChalkboardUser";
import { library } from '@fortawesome/fontawesome-svg-core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import api from "../../../store/Api/Intercepteur";


library.add(faChalkboardUser);

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

const HomeCard = () => {
    const type = useAppStore.use.type()
    const language = UseLanguage.use.language()
    const user = useAppStore.use.user()
    const userInfo = user.info
    const token = user.token
    const [dashboard, setDashboard] = useState([])

    const [textColor, setTextColor] = useState("text-black");

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const [recherche, setRecherche] = useState("");

    const navigation = useNavigate();

    const Recherche = () => {
        navigation("/recherche", { state: { recherche } });
    }
    const data = {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
            {
                label: 'Progression',
                data: [5, 2, 4, 6, 8, 14, 11],
                fill: true,
                borderColor: '#4caf50',
                tension: 0.4
            }
        ]
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    const fetchDashoard = async () => {
        if (type === "enseignant") {
            try {
                const response = await api.get('/dashboard/profs', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                setDashboard(response.data)
            } catch (error) {
                console.log(error)
            }
        } else if (type === "etudiant") {
            try {
                const response = await api.get('/dashboard/etudiants', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
                setDashboard(response.data)
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                const response = await api.get('/dashboard')
                setDashboard(response.data)
            } catch (error) {
                console.log(error)
            }
        }

    }

    useEffect(() => {
        fetchDashoard()
    }, [])
    return (
        <div className="w-full min-h-screen flex flex-col items-center justify-center">
            {type === "etudiant" || type === "" ? (
                <div
                    className="absolute bg-repeat  inset-0 bg-contain bg-fixed bg-center w-full h-full opacity-40"
                    style={{
                        backgroundSize: "cover",
                    }}
                >
                    <div className="w-full h-full fixed">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssvgjs="http://svgjs.dev/svgjs" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 1440 560">
                            <g mask="url(&quot;#SvgjsMask1761&quot;)" fill="none">
                                <rect width="1440" height="560" x="0" y="0" fill="url(&quot;#SvgjsRadialGradient1762&quot;)"></rect>
                                <path d="M454.83 568.21C654.94 529.04 740.92 23.12 1057.32 18.4 1373.72 13.68 1503.82 179.38 1659.81 180.8" stroke="rgba(45, 71, 142, 0.34)" strokeWidth="2"></path>
                                <path d="M614.18 573.95C773.06 558.27 876.44 220.71 1145.82 220.13 1415.2 219.55 1537.22 396.94 1677.46 399.33" stroke="rgba(45, 71, 142, 0.34)" strokeWidth="2"></path>
                                <path d="M724.79 665.51C867.13 618.2 897.1 210.4 1106.38 202.48 1315.66 194.56 1388.15 318.76 1487.97 320.08" stroke="rgba(45, 71, 142, 0.34)" strokeWidth="2"></path>
                                <path d="M307.55 574.86C474.14 565.6 599.47 264.96 898.32 264.43 1197.18 263.9 1337.94 392.49 1489.1 393.23" stroke="rgba(45, 71, 142, 0.34)" strokeWidth="2"></path>
                                <path d="M512.72 567.32C708.1 529.2 799.51 32.74 1101.3 31.06 1403.09 29.38 1528.24 293.6 1689.88 299.86" stroke="rgba(45, 71, 142, 0.34)" strokeWidth="2"></path>
                            </g>
                            <defs>
                                <mask id="SvgjsMask1761">
                                    <rect width="1440" height="560" fill="#ffffff"></rect>
                                </mask>
                                <radialGradient cx="100%" cy="50%" r="1466.97" gradientUnits="userSpaceOnUse" id="SvgjsRadialGradient1762">
                                    <stop stopColor="rgba(185, 216, 255, 1)" offset="0"></stop>
                                    <stop stopColor="rgba(233, 255, 240, 1)" offset="0.1"></stop>
                                    <stop stopColor="rgba(255, 255, 255, 1)" offset="0.21"></stop>
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            ) : (<></>)}
            {type === "administrateur" ? (
                <div className="py-6 px-12 space-y-12 bg-gray-100 min-h-screen w-[85vw] ml-60">
                    <div className="flex flex-col h-full w-full mx-auto  space-y-6">
                        <section className="flex flex-col mx-auto bg-white rounded-lg p-6 shadow-md space-y-6 w-full">
                            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                                <form className="flex flex-col relative md:col-span-3">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>
                                    </div>
                                    <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-200 rounded bg-gray-50" placeholder="Search here..." required />
                                    <button type="submit" className="text-blue-500 absolute end-2.5 top-1.5 border border-blue-500 hover:bg-blue-50 focus:outline-none font-medium rounded text-base px-4 py-2">Search</button>
                                </form>
                                <div className="col-span-1 flex items-center shadow">
                                    <button className="relative  px-4 py-2 rounded w-80 items-center justify-center bg-blue-500 font-medium hover:bg-primary-900">
                                        New facility
                                    </button>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0">
                                <div class="flex flex-col px-6 py-2 bg-white shadow rounded-lg overflow-hidden">
                                    <div class="flex flex-col items-center space-y-2">
                                        <div class="text-6xl font-bold tracking-tight leading-none text-blue-500">{dashboard?.nbProfs}</div>
                                        <div class="text-lg font-medium text-blue-500">Enseignants</div>
                                    </div>
                                </div>
                                <div class="flex flex-col px-6 py-2 bg-white shadow rounded-lg overflow-hidden">
                                    <div class="flex flex-col items-center space-y-2">
                                        <div class="text-6xl font-bold tracking-tight leading-none text-amber-500">{dashboard?.nbEtudiants}</div>
                                        <div class="text-lg font-medium text-amber-600">Etudiants</div>
                                    </div>
                                </div>
                                <div class="flex flex-col px-6 py-2 bg-white shadow rounded-lg overflow-hidden">
                                    <div class="flex flex-col items-center space-y-2">
                                        <div class="text-6xl font-bold tracking-tight leading-none text-red-500">{dashboard?.nbCours}</div>
                                        <div class="text-lg font-medium text-red-600">Cours</div>
                                    </div>
                                </div>
                                <div class="flex flex-col px-6 py-2 bg-white shadow rounded-lg overflow-hidden">
                                    <div class="flex flex-col items-center space-y-2">
                                        <div class="text-6xl font-bold tracking-tight leading-none text-primary-900">{dashboard?.nbUsers}</div>
                                        <div class="text-lg font-medium text-primary-900">Utilisateurs</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            ) : (
                type === "enseignant" ? (
                    <div className="relative flex flex-col justify-between items-start pt-5 w-[80%] ml-60 h-full mb-[500px]">
                        <div className="relative shadow-md rounded-lg w-full h-[70vh] bg-white">
                            <div className="absolute right-0 bottom-0">
                                <img src="https://lms.rocket-soft.org/store/1/dashboard.png" alt="" />
                            </div>
                            <div className="h-full w-1/2 flex flex-col justify-between py-3">
                                <div>
                                    <p className="text-blue font-bold text-xl ml-4">{translate(language, "dashboard")}</p>
                                </div>
                                <div className="pl-5">
                                    <h1 className="text-secondary font-bold text-4xl z-40">{translate(language, "hi")} {userInfo.name},</h1>
                                    <p className="text-secondaryblue font-bold text-xl mt-1">{translate(language, "youHave")} 5 {translate(language, "newEvent")}</p>
                                </div>
                                <div>
                                    <button className="text-blue font-bold text-base pl-5">{translate(language, "viewAllEvent")}</button>
                                </div>
                            </div>
                            <div className="z-10 relative flex p-5 justify-between max-lg:flex-wrap">
                                <div className="p-5 w-72 h-96 rounded-lg bg-white shadow-cardShadow flex flex-col justify-center ">
                                    <div className="flex flex-col items-center border-b">
                                        <img src="/acount.svg" alt="acount" />
                                        <p className="text-[#818894] text-[18px] mt-5">{translate(language, "balance")}</p>
                                        <p className="text-blue text-3xl font-extrabold">${userInfo.credit}</p>
                                    </div>
                                    <button className="mt-7 text-[#171347] font-extrabold text-lg flex flex-col items-center w-full">
                                        <p>{translate(language, "chargeAccount")}</p>
                                    </button>
                                </div>
                                <div className="px-4 grid grid-cols-2 w-2/3 flex-wrap justify-around items-start gap-5 text-nowrap">
                                    <div className="w-64 h-28 hover:shadow-lg py-5 pl-2 rounded-lg bg-white/80 shadow-cardShadow flex">
                                        <div className="bg-gradient-1 shadow-custom-1 p-5 ml-2 w-16 h-16 rounded-full">
                                            <img src="/support.svg" alt="" />
                                        </div>
                                        <div className="ml-5">
                                            <p className="text-blue text-3xl font-extrabold">{dashboard?.nbCoursProfSuivies}</p>
                                            <p className="text-[#818894] text-[18px]">{translate(language, "purchased")}</p>
                                        </div>
                                    </div>
                                    <div className="w-64 h-28 hover:shadow-lg py-5 pl-2 rounded-lg bg-white/80 shadow-cardShadow  flex">
                                        <div className="bg-gradient-2 ml-2 shadow-custom-1 p-5 w-16 h-16 rounded-full">
                                            <img src="/teacher.svg" alt="meeting" />
                                        </div>
                                        <div className="ml-5">
                                            <p className="text-blue text-3xl font-extrabold">{dashboard?.nbCoursProf}</p>
                                            <p className="text-[#818894] text-[18px]">{translate(language, "yaCourse")}</p>
                                        </div>
                                    </div>
                                    <div className="w-64 h-28 hover:shadow-lg rounded-lg py-5 pl-2 bg-white/80 shadow-cardShadow flex">
                                        <div className="bg-gradient-3 ml-2 shadow-custom-1 p-5 w-16 h-16 rounded-full">
                                            <img src="/student.svg" alt="meeting" />
                                        </div>
                                        <div className="ml-5">
                                            <p className="text-blue text-3xl font-extrabold">0</p>
                                            <p className="text-[#818894] text-[18px]">{translate(language, "yourStudent")}</p>
                                        </div>
                                    </div>
                                    {/* <div className="w-64 h-28 hover:shadow-lg rounded-lg py-5 bg-gray-100 flex">
                                        <div className="bg-gradient-4 ml-2 shadow-custom-1 p-5 w-16 h-16 rounded-full">
                                            <img src="/comment.svg" alt="comment" />
                                        </div>
                                        <div className="ml-5">
                                            <p className="text-blue text-3xl font-extrabold">0</p>
                                            <p className="text-[#818894] text-[18px]">Comments</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="relative flex flex-col items-center justify-center w-full mb-20">
                        <div className={`text-white z-10 flex items-center justify-center w-5/6 mt-8 max-sm:w-full max-md:block max-md:h-screen ${textColor}`}>
                            <div className="flex flex-col justify-center h-full items-center w-12 max-md:hidden">
                                <FontAwesomeIcon icon={faPen} className="text-thirdly mb-2" />
                                <div className="h-52 w-1 bg-gradient-to-b from-green-200 from-10% via-primary via-50% to-thirdly to-90% rounded"></div>
                                <FontAwesomeIcon icon={faPersonRunning} className="text-2xl font-extrabold text-thirdly my-2" />
                                <div className="h-60 w-1 bg-gradient-to-b from-green-200 from-10% via-primary via-50% to-thirdly to-90% rounded"></div>
                                <FontAwesomeIcon icon={faGraduationCap} className="text-thirdly  p-1 my-2" />
                            </div>
                            <div className="flex justify-around w-5/6 items-center max-md:w-full max-md:h-full max-md:block">
                                <div className="w-2/3 max-md:full ml-10 h-full flex flex-col justify-around max-md:items-center max-md:justify-center max-md:w-full max-md:ml-0">
                                    <div>
                                        <div className="flex text-4xl max-lg:text-3xl font-semibold text-black/80 mb-5 max-md:hidden">
                                            <h1 className="mr-2">{translate(language, "welcome")}</h1>
                                            <div className="">
                                                <Typewriter
                                                    onInit={(typewriter) => {
                                                        typewriter
                                                            .typeString(translate(language, "student"))
                                                            .pauseFor(500)
                                                            .deleteChars(translate(language, "student").length)
                                                            .typeString(translate(language, "teacher"))
                                                            .pauseFor(500)
                                                            .deleteChars(translate(language, "teacher").length)
                                                            .typeString(translate(language, "colaborator"))
                                                            // .pauseFor(500)
                                                            // .deleteChars(translate(language, "colaborator").length)
                                                            // .typeString(translate(language, "employee"))
                                                            .start();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <p className="text-lg text-textColor max-md:text-black/80 max-md:text-center max-md:px-7 max-md:font-bold">{translate(language, "titleAfterWelcome")}</p>
                                    </div>
                                    <div className="flex justify-start items-center mt-11 w-96 h-12 max-md:w-5/6">
                                        <SearchBar />
                                    </div>
                                </div>
                                <div className="w-1/3 max-lg:hidden">
                                    <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
                                </div>
                            </div>
                        </div>
                        {type === "etudiant" ? (
                            <div className="relative flex items-center mt-8 justify-around w-5/6 max-lg:flex-wrap px-16 max-lg:grid max-lg:grid-cols-2 max-lg:ml-36 max-md:ml-0 max-md:flex">
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56  flex flex-col justify-center items-center rounded-lg border border-gray-200  hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="icon-container w-[85px] h-[85px] bg-gradient-to-br from-sky-700 from-20% via-sky-600 via-40% to-sky-300 p-3 justify-center rounded-2xl flex flex-col items-center">
                                        <img src="/teacher.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">8</p>
                                    <h2 className="text-center font-semibold text-nowrap">Vos matières</h2>
                                </div>
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56  flex flex-col justify-center items-center rounded-lg border border-gray-200 hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="bg-gradient-to-tr w-[85px] h-[85px] from-purple-500 to-pink-500 p-3 justify-center rounded-2xl flex flex-col items-center">
                                        <img src="/student.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">8</p>
                                    <h2 className="text-center font-semibold text-nowrap">Vos cours</h2>
                                </div>
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56  flex flex-col justify-center items-center rounded-lg border border-gray-200 hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="bg-gradient-to-tl w-[85px] h-[85px] from-emerald-500 to-primary justify-center p-3 rounded-2xl flex flex-col items-center">
                                        <img src="/video.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">8</p>
                                    <h2 className="text-center font-semibold text-nowrap">Examen en approche</h2>
                                </div>
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56 flex flex-col justify-center items-center rounded-lg border border-gray-200 hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="bg-gradient-to-bl w-[85px] h-[85px] from-red-600 from-20% via-red-500 via-40% to-red-300 justify-center p-3 rounded-2xl flex flex-col items-center">
                                        <img src="/course.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">8</p>
                                    <h2 className="text-center font-semibold text-nowrap">Examen en approche</h2>
                                </div>
                            </div>
                        ) : (
                            <div className="relative flex items-center mt-8 justify-around w-5/6 max-lg:flex-wrap px-16 max-lg:grid max-lg:grid-cols-2 max-lg:ml-36 max-md:ml-0 max-md:flex">
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56  flex flex-col justify-center items-center rounded-lg border border-gray-200  hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="icon-container w-[85px] h-[85px] bg-gradient-to-br from-sky-700 from-20% via-sky-600 via-40% to-sky-300 p-3 justify-center rounded-2xl flex flex-col items-center">
                                        <img src="/teacher.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">{dashboard?.nbCours}</p>
                                    <h2 className="text-center font-semibold text-nowrap">Cours</h2>
                                </div>
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56  flex flex-col justify-center items-center rounded-lg border border-gray-200 hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="bg-gradient-to-tr w-[85px] h-[85px] from-purple-500 to-pink-500 p-3 justify-center rounded-2xl flex flex-col items-center">
                                        <img src="/student.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">{dashboard?.nbProfs}</p>
                                    <h2 className="text-center font-semibold text-nowrap">Enseigants</h2>
                                </div>
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56  flex flex-col justify-center items-center rounded-lg border border-gray-200 hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="bg-gradient-to-tl w-[85px] h-[85px] from-emerald-500 to-primary justify-center p-3 rounded-2xl flex flex-col items-center">
                                        <img src="/video.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">{dashboard?.nbUsers}</p>
                                    <h2 className="text-center font-semibold text-nowrap">Utilisateurs</h2>
                                </div>
                                <div className="bg-white p-4 w-56 shadow-cardShadow h-56 flex flex-col justify-center items-center rounded-lg border border-gray-200 hover:-translate-y-7 transition duration-1000 mb-20 hover:bg-[#1d3860] hover:border-none hover:text-white text-black/80">
                                    <div className="bg-gradient-to-bl w-[85px] h-[85px] from-red-600 from-20% via-red-500 via-40% to-red-300 justify-center p-3 rounded-2xl flex flex-col items-center">
                                        <img src="/course.svg" alt="" />
                                    </div>
                                    <p className="text-3xl font-bold my-2">{dashboard?.nbEtudiants}</p>
                                    <h2 className="text-center font-semibold text-nowrap">Etudiants</h2>
                                </div>
                            </div>
                        )}
                        <div className="relative h-[70vh] w-[70vw] shadow-cardShadow rounded bg-white max-md:w-[95%]">
                            <Carousel
                                showThumbs={false}
                                statusFormatter={() => { return null }}
                                interval={2000}
                                autoPlay={true}
                                infiniteLoop={true}
                                showArrows={false}
                            >
                                <div className="w-full h-full ">
                                    <div className="w-full h-[70vh] relative flex flex-col justify-center items-end px-5 max-lg:items-center">
                                        <div className="absolute left-5 max-lg:-right-16 max-lg:bottom-5">
                                            <img src="/Carousel1.jpeg" className="object-contain w-[200px] h-[250px] max-md:w-[350px] max-md:h-[250px]" />
                                        </div>
                                        <div className="absolute w-1/2 max-lg:w-4/5 max-lg:top-10 max-lg:text-[14px] pr-7 max-lg:pr-1">
                                            <p className="text-4xl text-black/70 font-bold text-end max-lg:text-center max-sm:text-3xl">{translate(language, "titreCarousel1")}</p>
                                            <p className="text-end max-lg:text-center">{translate(language, "descriptioCarousel1")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full h-[70vh] bg-[#275ec2] relative flex flex-col justify-center items-start px-5 max-lg:items-center">
                                        <div className="absolute right-16 max-lg:right-[35%] max-sm:right-[30%] max-lg:bottom-5">
                                            <img src="/Carousel2.jpeg" className="object-contain w-[500px] h-[400px] max-lg:w-[400px] max-lg:h-[270px] max-md:h-[200px]" />
                                        </div>
                                        <div className="absolute w-1/2 ml-5 max-lg:w-4/5  max-lg:top-10 max-lg:text-[14px]">
                                            <p className="text-4xl text-white font-bold text-center max-md:text-3xl">{translate(language, "titreCarousel2")}</p>
                                            <p className="text-white/70 text-center">{translate(language, "descriptioCarousel2")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full h-full ">
                                    <div className="w-full h-[70vh] relative flex flex-col justify-center items-end px-5 max-lg:items-center">
                                        <div className="absolute left-0 max-lg:right-14 max-lg:bottom-0">
                                            <img src="Carousel3.jpeg" className="object-contain w-[500px] h-[400px] ml-7 max-md:w-[350px] max-lg:h-[330px] max-sm:h-[250px]" />
                                        </div>
                                        <div className="absolute w-1/2 max-lg:w-4/5 max-md:w-5/6 max-lg:top-10 text-black/80 pr-10 max-md:text-[14px]">
                                            <p className="text-4xl font-bold text-end max-lg:text-center max-sm:text-2xl max-lg:text-3xl">{translate(language, "titreCarousel3")}</p>
                                            <p className="text-end max-lg:text-center max-md:mt-1 rounded">{translate(language, "descriptioCarousel3")}</p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div >
                    </div >
                )
            )}
        </div >
    );
};

export default HomeCard;
