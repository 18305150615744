import React, { useEffect, useState } from "react";
import { ExamenNavigation } from "../../Components/NavBar/ExamenNavigation";
import { SearchBar } from "../../Components/Searchbar/SearchBar";
import { PasserExamenCard } from "../../Components/Cards/Etudiant/PasserExamenCard";
import axios from "axios";
import api from "../../store/Api/Intercepteur";
import { useLocation } from "react-router-dom";

export const PasserExamen = () => {
    const [Examen, setExamen] = useState([])
    const location = useLocation()
    const id = location.state.id

    const fetchExamen = async () => {
        try {
            const response = await api.get(`/chapitres/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
            setExamen((prev) => {
                return response.data.exercices
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchExamen()
    }, [])
    return (
        <div className="w-full flex flex-col justidy-center items-center px-52">
            <PasserExamenCard examen={Examen} />
        </div>
    )
}